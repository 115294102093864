import { useEffect, useState } from "react";
import toastr from 'toastr';

import { AuthForm } from "./AuthForm";

import { ModalWindow } from "../ModalWindow";

import { IAuthData } from '../../types/IAuthData';
import { IUser } from '../../types/IUser';

import { getAuth } from '../../data/auth';

import { validateData } from '../../helpers/validateData';

import { useUserStore, IUserStore } from '../../store/user';

export function Auth() {
  const user = useUserStore((state: IUserStore) => state.user);
  const [active, setActive] = useState<boolean>(!(user as IUser).id);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const [confirmBtnText, setConfirmBtnText] = useState<string>(isAuth ? 'Войти' : 'Зарегистрироваться');
  const [authFormData, setAuthFormData] = useState<IAuthData>({
    username: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    isLogin: isAuth
  });

  useEffect(() => {
    setConfirmBtnText(isAuth ? 'Войти' : 'Зарегистрироваться');
  }, [isAuth]);

  useEffect(() => {
    setActive(!(user as IUser).id);
  }, [user]);

  const clickHandler = () => {
    const validData = validateData(authFormData);
    if (validData.isValid) {
      getAuth(authFormData).then((response: any) => {
        if (response.status && response.message) {
          response.status === 'SUCCESS'
            ? toastr.success(response.message, '', { closeButton: true })
            : toastr.error(response.message, 'Возникла проблема!', { closeButton: true });
        }
        setActive(false);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
        .catch((error: any) => {
          const res = error.response.data
          if (res.status && res.message) {
            toastr.error(res.message, '', { closeButton: true });
          } else {
            const errors = res.errors.errors[0]
            toastr.error(errors.msg, res.message, { closeButton: true });
          }
        })
    } else {
      console.log(validData.message);
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    return (e.key === 'Enter') ? clickHandler()
      : (e.key === 'Escape') ? setActive(false) : ''
  }

  return (
    <ModalWindow active={active} setActive={setActive} >
      <div className="auth__content" onKeyDown={onKeyDown}>
        <div className="auth__header">
          <h2 onClick={() => setIsAuth(false)} className={`auth__registration ${!isAuth ? 'active' : ''}`}>Регистрация</h2>
          <h2 onClick={() => setIsAuth(true)} className={`auth__authorization ${isAuth ? 'active' : ''}`}>Авторизация</h2>
        </div>

        <AuthForm auth={isAuth} authFormData={authFormData} setAuthFormData={setAuthFormData} />

        <div className="auth__btns">
          <div onClick={() => setActive(false)} className="auth__btn cancel">Назад</div>
          <div onClick={clickHandler} className="auth__btn confirm">{confirmBtnText}</div>
        </div>
      </div>
    </ModalWindow>
  );
}
