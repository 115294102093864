import React, { useEffect, useState } from 'react';

import { Content } from '../components/Content';
import { ChatList } from '../components/Chats/ChatList';
import { MessagesList } from '../components/Messages/MessagesList';

import { IChat } from '../types/IChat';

import { useUserStore, IUserStore } from '../store/user';

import { Auth } from '../components/Auth/Auth';

export function Chat() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);

  const user = useUserStore((state: IUserStore) => state.user);
  const [currentChat, setCurrentChat] = useState<IChat>();
  
  const [activeChats, setActiveChats] = useState<boolean>(true);

  useEffect(() => {
    console.log(currentChat);
  }, [currentChat]);

  if(!user.id){
    return <Auth />
  }

  return (
    <>
      <Content sideBarActive={sideBarActive}>
        <div className="chat__window">
          <ChatList setCurrentChat={setCurrentChat} currentChat={currentChat} activeChats={activeChats} setActiveChats={setActiveChats}/>
          <MessagesList currentChat={currentChat} setActiveChats={setActiveChats}/>
        </div>
      </Content>
    </>
  );
}
