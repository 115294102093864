import React from "react";

import { Authorization } from "./Authorization";
import { UserSettings } from "./UserSettings";

export function SettingsBlock() {
  return (
    <div className="settings">
      <Authorization />
      <UserSettings />
    </div>
  );
}