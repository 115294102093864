import React from "react";

import { IMedia } from "../../types/IMedia";

import remove from '../../assets/plus.png';

export function SendWindowFilePreview({ file, setCurrentMedia }: { file: IMedia | undefined, setCurrentMedia: React.Dispatch<React.SetStateAction<IMedia | undefined>> }) {
  if (!file?.base64) {
    return (<></>);
  }

  const removeHandler = () => {
    setCurrentMedia({
      name: '',
      type: '',
      size: 0,
      base64: ''
    });
  }

  return (
    <div className="send__window__body__media__preview">
      <div className="send__window__body__media__preview__remove">
        <img onClick={removeHandler} className="send__window__body__media__preview__remove__icon" src={remove} alt="remove" />
      </div>
      <img src={file.base64} alt="" className="send__window__body__media__preview__img" />
      <p className="send__window__body__media__name">{ file.name }</p>
    </div>
  );
}