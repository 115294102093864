import { useEffect, useState } from "react";
import { getStatus } from "../../data/status";
import { IDataState } from "../../types/IDataState";

import { useUserStore, IUserStore } from '../../store/user';

import { Change } from '../Icons/Send/Change';

export function ReceivingStatus() {
  const user = useUserStore((state: IUserStore) => state.user);

  const [dataStates, setDataStates] = useState<IDataState[]>([]);
  const [cicleUpdateFlag, setCicleUpdateFlag] = useState<boolean>(false);
  const [inUpdate, setInUpdate] = useState<boolean>(false);
  
  useEffect(() => {
      getStatus({}, 'get').then((data: { status: string, dataStates: IDataState[] }) => {
        setDataStates(data.dataStates);
      });
  }, [cicleUpdateFlag]);
  
  useEffect(() => {
    if(!!user?.authorize){
      const updateInterval = setInterval(() => {
        setCicleUpdateFlag(cicleUpdateFlag => !cicleUpdateFlag);  
      }, 20 * 1000);
      return () => {clearInterval(updateInterval)}
    }
  }, [user])

  useEffect(() => {
      console.log(dataStates);
    setInUpdate(dataStates[0]?.statusCode === 1 && dataStates[1]?.statusCode === 1);
  }, [dataStates]);


  return (
    <div className={`receiving__status ${inUpdate ? '' : 'hidden'}`}>
      <div className="receiving__status__img__block">
        <svg className="receiving__status__img rotating" version="1.0" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64.000000 64.000000"
          preserveAspectRatio="xMidYMid meet"
          fill="#ffffff"
          >
          <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
          stroke="none"
          fill="#ffffff"
          >
          <path d="M205 616 c-65 -27 -165 -121 -165 -156 0 -40 24 -32 73 24 69 79 113
          101 207 101 87 0 133 -20 190 -81 23 -25 27 -32 11 -21 -26 17 -51 13 -51 -9
          0 -19 119 -106 139 -102 20 4 39 154 23 170 -16 16 -42 1 -42 -24 0 -20 -5
          -17 -47 24 -94 92 -225 121 -338 74z"/>
          <path d="M15 258 c-2 -7 -6 -47 -7 -88 -3 -69 -1 -75 17 -75 12 0 21 9 25 23
          5 22 7 21 49 -22 64 -65 127 -91 226 -90 66 0 89 5 131 26 56 30 144 120 144
          148 0 40 -24 32 -73 -24 -69 -79 -113 -101 -207 -101 -87 0 -133 20 -190 81
          -23 25 -27 32 -11 21 26 -17 51 -13 51 9 0 16 -115 104 -136 104 -8 0 -17 -6
          -19 -12z"/>
          </g>
        </svg>
      </div>                           
      <p className="receiving__status__text">Идёт синхронизация</p>                           
    </div>
  );
}