import { useState } from 'react';

export function Logo() {
  const [fillColor, setFillColor] = useState<string>('white');

  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? '#00a884' : 'white');
  }

  return (
    <svg className="header__logo__icon" width="100" height="62" viewBox="0 0 100 62" fill={fillColor} xmlns="http://www.w3.org/2000/svg" onMouseEnter={() => hoverHandler(true)} onMouseLeave={() => hoverHandler(false)} >
      <path d="M59.7368 39.747V40.0335H59.7854V39.8146L59.7368 39.747Z" />
      <path d="M78.4117 20.1113H100V40.0592H78.4117V40.0335H59.9435L59.7854 39.8146L59.7368 39.747L38.8687 10.7144V2.35261C38.8687 1.7148 39.1011 1.16347 39.566 0.69727C40.0308 0.232423 40.5822 0 41.2227 0H57.4315C58.0706 0 58.622 0.232423 59.0882 0.69727C59.5517 1.16347 59.7854 1.7148 59.7854 2.35261V20.1627H78.4117V20.1113Z" />
      <path d="M38.8687 27.9354L59.7854 57.0355V58.6517C59.7854 59.2908 59.5516 59.8422 59.0881 60.307C58.6219 60.7732 58.0706 61.0043 57.4315 61.0043H43.4009C42.0064 61.0043 40.8429 60.3949 39.9146 59.1746L20.9167 34.9472V58.6517C20.9167 59.2908 20.6829 59.8422 20.2194 60.307C19.7533 60.7732 19.2019 61.0043 18.5628 61.0043H2.35396C1.71344 61.0043 1.16212 60.7732 0.69727 60.307C0.232423 59.8422 0 59.2908 0 58.6517V2.35261C0 1.7148 0.232423 1.16347 0.69727 0.69727C1.16212 0.232423 1.71344 0 2.35396 0H16.4723C17.8668 0 18.9992 0.581061 19.8708 1.74318L38.8687 27.9745V27.9354Z" />
      <path d="M100 2.47153V10.0604H78.4117V2.47153C78.4117 1.83372 78.6319 1.28104 79.0751 0.816197C79.5184 0.352702 80.0427 0.118927 80.6535 0.118927H97.7582C98.3676 0.118927 98.8919 0.352702 99.3365 0.816197C99.7784 1.28104 100 1.83372 100 2.47153Z" />
      <path d="M78.4117 50.1101H100V58.5962C100 59.2354 99.7784 59.7881 99.3365 60.2516C98.8919 60.7164 98.3676 60.9489 97.7582 60.9489H80.6535C80.0427 60.9489 79.5184 60.7164 79.0751 60.2516C78.6319 59.7881 78.4117 59.2354 78.4117 58.5962V50.1101Z" />
    </svg>
  );
}