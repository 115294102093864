import { useEffect, useState } from "react";

export function AuthorizationQr({ qr, authorize }: { qr: string, authorize: number | string }) {
  const [imageText, setImageText] = useState(authorize 
    ? 'Вы уже отсканировали qr-код и можете пользоваться сервисом'
    : 'Здесь будет сгенерированный qr-код'
  );

  useEffect(() => {
    setImageText(authorize
      ? 'Вы уже отсканировали qr-код и можете пользоваться сервисом'
      : 'Здесь будет сгенерированный qr-код'
    );
  }, [authorize]);
  
  if (qr) {
    return (
      <img src={qr} alt="" className="settings__auth__qr__image" />
    );
  }

  return (
    <div className="settings__auth__qr__image">
      { imageText }
    </div>
  );
}
