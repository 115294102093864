import { IEnumerable } from "../types/IEnumerable";

import { getTimeFromTimeStamp } from '../helpers/getTimeFromTimestamp';

import { IPerson } from "../types/IPerson";

export function StatisticPerson({ person, idx }: { person: IPerson, idx: number }) {
  const statuses: IEnumerable = { 
    success: 'Успешно',
    waiting: 'В процессе',
    unsubscribed: 'Отписался',
    error: 'С ошибкой',
  }

  const personStatus = person.sended 
    ? person.status === 1 
    ? 'success' : person.status === 2 
    ? 'unsubscribed' : 'error' : 'waiting';

  return (
    <tr>
      <th scope="row">{ idx + 1 }</th>
      <td>{ person.name || 'Имя не указано' }</td>
      <td>{ person.phone }</td>
      <td className={`send__status ${personStatus}`}>{ statuses[personStatus] }</td>
      <td>{ getTimeFromTimeStamp(+person.datetime) }</td>
    </tr>
  );
}
