import { IDateBlock } from "../types/IDateBlock";
import { IMessage } from "../types/IMessage";

import { getTimeFromTimeStamp } from './getTimeFromTimestamp';
import { compareStrings } from "./compareStrings";

function getMessagesByDate(messages: IMessage[], date: string) {
  return messages
    .filter((message: IMessage) => getTimeFromTimeStamp(+message.date * 1000).split(' ')[0] === date)
    .map((message: IMessage) => ({ ...message, date: getTimeFromTimeStamp(+message.date * 1000).split(' ')[1] }))
    .reverse()
}

export function prepareMessages(dateBlocks: IDateBlock[], messages: IMessage[]): IDateBlock[] {
  const dates = Array
    .from(new Set(messages.map((message: IMessage) => getTimeFromTimeStamp(+message.date * 1000).split(' ')[0])))
    .filter((date: string) => date);

  dates.forEach((date: string) => {
    const curentDateBlock = dateBlocks.find(db => db.date === date);
    if (curentDateBlock?.messages.length) {
      curentDateBlock.messages = [...getMessagesByDate(messages, date), 
        ...curentDateBlock.messages
      ];
    } else {
      dateBlocks.push({
        date,
        messages: getMessagesByDate(messages, date)
      });
    }
  });

  return dateBlocks.sort((dbA, dbB) => compareStrings(dbA.date, dbB.date)).reverse();
}

export function prepareMessagesBottom(dateBlocks: IDateBlock[], messages: IMessage[]): IDateBlock[] {
  const dates = Array
    .from(new Set(messages.map((message: IMessage) => getTimeFromTimeStamp(+message.date * 1000).split(' ')[0])))
    .filter((date: string) => date);

  dates.forEach((date: string) => {
    const curentDateBlock = dateBlocks.find(db => db.date === date);
    if (curentDateBlock?.messages.length) {
      curentDateBlock.messages = [...curentDateBlock.messages,
      ...getMessagesByDate(messages, date)
      ];
    } else {
      dateBlocks.push({
        date,
        messages: getMessagesByDate(messages, date)
      });
    }
  });

  return dateBlocks.sort((dbA, dbB) => compareStrings(dbA.date, dbB.date)).reverse();
}