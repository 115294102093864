import { IChat } from "../../types/IChat";
import { Search } from '../Icons/Search';
import { Close } from '../Icons/Close';

export function MessageFindIcon({active, currentChat} : {active: boolean, currentChat: IChat | undefined}) {
  if(currentChat){
    return active ? <Close /> : <Search /> 
  }else{
    return null
  }
}

