import create from 'zustand';

import { IDocs } from '../types/IDocs';

export interface IDocsStore {
  docs: IDocs[]
  initialDocs: (docs: IDocs[]) => void
}

export const useDocsStore = create<IDocsStore>()((set: any) => ({
  docs: [],
  initialDocs: (docs: IDocs[]) => set(() => ({ docs }))
}));
