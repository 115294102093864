import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getUsers(params: any): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/users',
    method: params.method || 'get',
    params,
    data: params,
  }

  return new Promise((resolve, reject) => {
    basicQuery(options).then((response: any) => {
      const { data } = response;

      if (!data.status)
        reject('Error in proccess getting data of dialogs!');

      resolve(data);
    })
  })
}

