import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getLogOut(params: any): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/auth/logout',
    method: 'post',
    params,
    data: params
  }

  return new Promise((resolve, _) => {
    basicQuery(options).then((response: any) => {
      const { data } = response;

      resolve(data);
    })
  })
}
