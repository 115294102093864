import { HeaderUserOptions } from './HeaderUserOptions';

import temporaryAvatar from '../../assets/user.png';
import { Logo } from '../Icons/Logo';

import { IUser } from '../../types/IUser';

export function Header({ user, sideBarActive }: { user: IUser, sideBarActive: boolean }) {
  return (
    <header className={`header ${sideBarActive ? 'small' : ''}`}>
      <div className="container">
        <div className="header__inner">

          <div className="header__inner__left">
            <div className="header__logo">
              <Logo />
            </div>
            <h1 className="header__title">Nohau Whatsapp API</h1>
          </div>

          <div className="header__inner__center"></div>

          <div className="header__inner__right">
            <div className="header__theme">
              <div className='toggle-switch'>
                <label>
                  <input className="header__theme__input" type='checkbox' />
                  <span className= 'slider'></span>
                </label>
              </div>
            </div>

            <div className="header__user">
              <img src={user.logo || temporaryAvatar} alt="user-logotype" className="header__user__icon" />
              <p className="header__user__name">{user.username}</p>

              <div className="header__user__options">
                <HeaderUserOptions />
              </div>
            </div>
          </div>

        </div>
      </div>
    </header>
  );
}
