import { useState, useEffect } from 'react';

import { Header } from '../components/Header/Header';
import { SideBar } from '../components/SideBar/SideBar';
import { Content } from '../components/Content';
import { DocsList } from '../components/Docs/DocsList';
import { Auth } from '../components/Auth/Auth';

import { useUserStore, IUserStore } from '../store/user';
import { IDocsStore, useDocsStore } from '../store/docs';

import { getDocs } from '../data/docs';

import { IDocs } from '../types/IDocs';

export function Documentation() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);
  const user = useUserStore((state: IUserStore) => state.user);
  const initialDocs = useDocsStore((state: IDocsStore) => state.initialDocs);

  useEffect(() => {
    getDocs({}, 'get').then((docs: any) => {
      if (docs) {
        console.log('Docs: ', docs);
        initialDocs(docs.map((doc: any) => ({ ...doc, value: JSON.parse(doc.value) })));
      }
    });
  }, []);

  if(!user.id){
    return <Auth />
  }

  return (
    <>
     
      <Header sideBarActive={sideBarActive} user={user} />
      <SideBar setSideBarActive={setSideBarActive} />
      <Content sideBarActive={sideBarActive} >
        <DocsList />
      </Content>
    </>
  );
}
