import { useState, useEffect } from 'react';

export function Home({ fillColor }: { fillColor: string }) {
  const [currentFillColor, setCurrentFillColor] = useState<string>('white');

  useEffect(() => {
    setCurrentFillColor(fillColor);
  }, [fillColor]);

  return (
    <svg 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      width="32.000000pt" height="32.000000pt" 
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
      fill={currentFillColor} 
      className="sidebar__nav__item__icon"
    >

    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M75 225 c-95 -88 -80 -100 19 -15 l69 59 68 -61 c38 -34 73 -58 77
    -53 6 6 -136 146 -148 145 -3 -1 -41 -34 -85 -75z"/>
    <path d="M250 251 c0 -10 7 -24 15 -31 13 -11 15 -8 15 19 0 21 -5 31 -15 31
    -8 0 -15 -8 -15 -19z"/>
    <path d="M102 198 l-62 -50 0 -64 0 -64 45 0 c45 0 45 0 45 34 0 39 11 50 40
    41 15 -5 20 -15 20 -41 0 -33 1 -34 40 -34 l40 0 -1 68 0 67 -53 47 -53 47
    -61 -51z"/>
    </g>
    </svg>
  ); 
}