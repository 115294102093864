import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import { Home } from './pages/Home';
import { Settings } from './pages/Settings';
import { Send } from './pages/Send';
import { Chat } from './pages/Chat';
import { Documentation } from './pages/Documentation';
import { SendStatistic } from './pages/SendStatistic';

import { getUsers } from './data/users';

import { useUserStore, IUserStore } from './store/user';

function App() {
  const authorize = useUserStore((state: IUserStore) => state.authorize);

  getUsers({}).then((user: any) => {
    authorize(user.user);
  });

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/settings' element={<Settings />}/>
        <Route path='/send' element={<Send />}/>
        <Route path='/chat' element={<Chat />}/>
        <Route path='/documentation' element={<Documentation />}/>
        <Route path='/statistic' element={<SendStatistic />}/>
      </Routes>
    </Router> 
  );
}

export default App;
