export function prepareMessageCaption(caption: string) {
  caption.match(/http\S*/g)?.forEach(v => {
    if (v.match(/(\.jpg|\.png)/) === null) {
      caption = caption.replace(v, '<a href="' + v + '" target="_blank">' + v + '</a>');
    } else {
      caption = caption.replace(v, '<img src="' + v + '" alt="">');
    }
  });
  return caption;
}
