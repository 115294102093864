import React from "react";

export function DocsRequestItem({ param }: { param: any }) {
  return (
    <tr>
      <th>{param.name}</th>
      <td>{param.description}</td>
      <td>{param.dataType}</td>
      <td>{param.paramType}</td>
    </tr>
  );
}
