import React, { useState } from 'react';

export function Statistics({ statisticHandler }: { statisticHandler: () => void }) {
  const [fillColor, setFillColor] = useState<string>('white');
  const [active, setActive] = useState<boolean>(false);

  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? '#00a884' : 'white');
    setActive(hover);
  }

  return (
    <div className="send__body__mailing__item__button__icon" onClick={statisticHandler}>
      <div className={`send__body__mailing__item__button__icon__popup statistics ${active ? 'active' : ''}`}>
        <div className="send__body__mailing__item__button__icon__popup__text">Посмотреть детали отправки</div> 
        <div className="send__body__mailing__item__button__icon__popup__angle statistics"></div> 
      </div>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
        fill={fillColor}
        onMouseEnter={() => hoverHandler(true)} 
        onMouseLeave={() => hoverHandler(false)}
        >
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        stroke="none"
        fill={fillColor}
        >
        <path d="M92 582 c-9 -7 -12 -64 -10 -253 l3 -244 244 -3 c248 -2 267 0 254
        34 -4 11 -54 14 -226 16 l-222 3 -5 224 c-3 136 -9 226 -15 228 -5 1 -16 -1
        -23 -5z"/>
        <path d="M517 583 c-4 -3 -7 -94 -7 -200 l0 -194 23 3 c22 3 22 5 25 192 2
        145 -1 191 -10 198 -16 10 -23 10 -31 1z"/>
        <path d="M302 498 c-13 -13 -18 -262 -6 -292 8 -21 40 -21 48 0 3 9 6 75 6
        148 0 123 -6 156 -30 156 -3 0 -11 -5 -18 -12z"/>
        <path d="M404 437 c-2 -7 -3 -65 -2 -128 3 -110 4 -114 26 -117 22 -3 22 -3
        22 127 0 125 -1 131 -20 131 -11 0 -23 -6 -26 -13z"/>
        <path d="M190 280 c0 -89 1 -91 23 -88 21 3 22 7 22 88 0 81 -1 85 -22 88 -22
        3 -23 1 -23 -88z"/>
        </g>
      </svg>
    </div>
  );
}