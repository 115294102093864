import React, { useEffect, useState } from 'react';
import { getSendings } from '../../data/sendings';
import { ISendStore, useSendItemStore } from '../../store/sendItems';
import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';

import { ModalWindow } from '../ModalWindow';

export function StartWindow({ 
  active, 
  setActive, 
  currentSendItemId,
  setCurrentSendItemId,
}: { 
  active: boolean, 
  setActive: React.Dispatch<React.SetStateAction<boolean>>, 
  currentSendItemId: string 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>>
}) {

  const item = useSendItemStore((state: ISendStore) => state.sendItems.find(item => item.id === currentSendItemId));
  
  const changeSendItem = useSendItemStore((state: ISendStore) => state.changeSendItem);
  
  const started = (!!item && item.status === sendFilterStatuses.work);

  const clickHandler = (start:boolean) => {
    if (!start) {
      return setActive(false);
    }
    
    if (!item) return;
    
    const newSendItem = { 
      ...item, 
      processId: item.status !== sendFilterStatuses.complete ? item.processId : Date.now().toString(), 
      status: !started ? sendFilterStatuses.work : item.sended >= item.total ? sendFilterStatuses.complete : sendFilterStatuses.created 
    }

    getSendings(newSendItem, 'put').then(() => {
      changeSendItem(item.id, newSendItem);
    });

    setActive(false);
    setCurrentSendItemId('');
  }
  
  if(item?.status === sendFilterStatuses.work){
      return (
      <ModalWindow active={active} setActive={setActive}>
        <div className="send__remove__window">
          <h3 className="send__remove__window__title">Вы хотите остановить рассылку?</h3>
          <div className="send__remove__window__buttons">
            <button onClick={() => clickHandler(false)} className="send__remove__window__button cancel">Отменить</button>
            <button onClick={() => clickHandler(true)} className="send__remove__window__button confirm">Остановить рассылку</button>
          </div>
        </div>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow active={active} setActive={setActive}>
      <div className="send__remove__window">
        <h3 className="send__remove__window__title">Вы хотите запустить рассылку?</h3>
        <div className="send__remove__window__buttons">
          <button onClick={() => clickHandler(false)} className="send__remove__window__button cancel">Отменить</button>
          <button onClick={() => clickHandler(true)} className="send__remove__window__button confirm">Запустить рассылку</button>
        </div>
      </div>
    </ModalWindow>
  );
}