import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getMessages(params: any, method: string): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/messages',
    method,
    params: { ...params },
    data: { ...params }
  }

  return new Promise((resolve, reject) => {
    basicQuery(options).then((response: any) => {
      const { data } = response;
      const { messages } = data;

      if (!messages) {
        reject('Error in proccess getting data of messages!');
      }

      resolve(data);
    });
  });
}

