import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getPeople(params: any, method: string): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/people',
    method,
    params: { ...params },
    data: { ...params }
  }

  return new Promise((resolve, _) => {
    basicQuery(options).then((response: any) => {
      resolve(response.data);
    });
  });
}

