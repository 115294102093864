import create from 'zustand';

import { IUser } from '../types/IUser';

export interface IUserStore {
  user: IUser | any
  authorize: (user: IUser) => void
  deAuthorize: () => void
}

export const useUserStore = create<IUserStore>()((set: any) => ({
  user: {},
  authorize: (user: IUser) => set(() => ({ user })),
  deAuthorize: () => set(() => {
    return {
      user: { }
    }
  }),
}));

