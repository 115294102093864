import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getDocs(params: any, method: string): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/docs',
    method,
    params: { ...params },
    data: { ...params }
  }

  return new Promise((resolve, _) => {
    try {
      basicQuery(options).then((response: any) => {
        const { data } = response;
        const { docs } = data;

        resolve(docs);
      });
    } catch (e) {
      console.log('Error: ', e);
    }
  });
}
