import React, { useState } from 'react';

import { SendList } from './SendList';
import { SendFilterBlock } from './SendFilterBlock';

import plusIcon from '../../assets/plus.png';
import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';

export function SendComponent({ 
  setModalActive, 
  setStartActive, 
  setRemoveActive, 
  setCurrentSendItemId 
}: { 
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setStartActive: React.Dispatch<React.SetStateAction<boolean>>,
  setRemoveActive: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>> 
}) {
  const [filterStatus, setFilterStatus] = useState<string>(sendFilterStatuses.all);
  const [searchString, setSearchString] = useState<string>('');

  return (
    <div className="send">
      <div className="send__header">
        <h2 className="send__title">Рассылка</h2>
        <p className="send__subtitle">Контролируйте свои рассылки удобно!</p>
      </div>

      <div className="send__body">
        <h3 className="send__body__title">Ваши рассылки</h3>

        <div className="send__body__search__block">
          <input 
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString} 
            type="text" 
            className="send__body__search__block__input" 
            placeholder="Введите заголовок задачи"
          />
          <button className="send__body__search__block__new" onClick={() => setModalActive(true)}>
            <img src={plusIcon} alt="new" className="send__body__search__block__new__icon" />
          </button>
        </div>

        <div className="send__body__filter__block">
          <SendFilterBlock setFilterStatus={setFilterStatus} />
        </div>

        <SendList 
          searchString={searchString}
          filterStatus={filterStatus}
          setCurrentSendItemId={setCurrentSendItemId} 
          setStartActive={setStartActive} 
          setRemoveActive={setRemoveActive} 
          setModalActive={setModalActive} 
        />
      </div>
    </div>
  );
}