import React, { useEffect, useState } from 'react';

import { Header } from '../components/Header/Header';
import { SideBar } from '../components/SideBar/SideBar';
import { Content } from '../components/Content';
import { SendComponent } from '../components/Send/SendComponent';
import { SendWindow } from '../components/Send/SendWindow';
import { StartWindow } from '../components/Send/StartWindow';
import { RemoveWindow } from '../components/Send/RemoveWindow';
import { Auth } from '../components/Auth/Auth';

import { useUserStore, IUserStore } from '../store/user';
import { getSendings } from '../data/sendings';
import { ISendItemData } from '../types/ISendItem';
import { ISendStore, useSendItemStore } from '../store/sendItems';

export function Send() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [startActive, setStartActive] = useState<boolean>(false);
  const [removeActive, setRemoveActive] = useState<boolean>(false);
  const [currentSendItemId, setCurrentSendItemId] = useState<string>('');

  const user = useUserStore((state: IUserStore) => state.user);
  const intialSendItems = useSendItemStore((state: ISendStore) => state.initialSendItems);

  useEffect(() => {
    getSendings({}, 'get').then((data: ISendItemData) => {
      intialSendItems(data.sendings);
    });
  }, []);

  if(!user.id){
    return <Auth />
  }

  return (
    <>
      <Header sideBarActive={sideBarActive} user={user} />
      <SideBar setSideBarActive={setSideBarActive} />
      <Content sideBarActive={sideBarActive} >
        <SendComponent setCurrentSendItemId={setCurrentSendItemId} setModalActive={setModalActive} setStartActive={setStartActive} setRemoveActive={setRemoveActive} />
      </Content>
      <SendWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId} active={modalActive} setActive={setModalActive} />
      <StartWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId} active={startActive} setActive={setStartActive} />
      <RemoveWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId} active={removeActive} setActive={setRemoveActive} />
    </>
  );
}
