import React from 'react';
import { ISendFilterButtonsStore, useSendFilterButtonsStore } from '../../store/sendFilterButtons';
import { SendFilterBtn } from './SendFilterBtn';

export function SendFilterBlock({ setFilterStatus }: { setFilterStatus: React.Dispatch<React.SetStateAction<string>> }) {
  const buttons = useSendFilterButtonsStore((state: ISendFilterButtonsStore) => state.buttons);
  return (
    <div className="send__body__filter__block__list">
      {
        buttons && buttons.map(btn => {
          return <SendFilterBtn setFilterStatus={setFilterStatus} btn={btn} key={btn.id} />
        })
      }
    </div>
  );
}