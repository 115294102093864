import { IEnumerable } from '../types/IEnumerable';

const hasEmptyField = (arr: string[]) => {
  arr.forEach((field: string) => {
    if (field && !field.trim().length) {
      return true;
    }
  });
  return false;
}

export function validateData(data: IEnumerable): { isValid: boolean, message: string } {
  let isValid = true;
  let message = 'Подождите завершения регистрации';

  if (data.isLogin) {
    const { email, password } = data;

    isValid = !hasEmptyField([email, password]);
    message = isValid ? 'Успешно' : 'Введены некорректные данные при регистрации';
  } else {
    for (const key in data) {
      if (data[key] !== 'isLogin' && hasEmptyField([data[key]])) {
        return {
          isValid: false,
          message: 'Введены некорректные данные при регистрации'
        }
      }
    }
  }
  return {
    isValid,
    message
  }
}
