import React from 'react';
import { useUserStore, IUserStore } from '../store/user';

export function ModalWindow({ children, active, setActive }: { children: string | JSX.Element, active: boolean, setActive: React.Dispatch<React.SetStateAction<boolean>> }) {
  
  const { id } = useUserStore((state: IUserStore) => state.user);

  const handleClick = () => {
    if (!!id) {
      setActive(false);
    }
  };

  return (
    <div className={`modal__window ${active ? 'active' : ''}`} onClick={handleClick}>
      <div className="modal__window__inner" onClick={e => e.stopPropagation()}>
        { children }
      </div>
    </div>
  );
}