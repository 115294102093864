import { IChat } from "../../types/IChat"
import { IMessage } from "../../types/IMessage"
import { getTimeFromTimeStamp } from "../../helpers/getTimeFromTimestamp"


export function MessageFind ({active, chat, findMessages, setActiveFind, setFindMessageDate, setFindMessageId} : 
	{active: boolean, chat: IChat | undefined, findMessages: any, setActiveFind:  React.Dispatch<React.SetStateAction<boolean>>, 
		setFindMessageDate:  React.Dispatch<React.SetStateAction<string>>, setFindMessageId:  React.Dispatch<React.SetStateAction<string>>}) {
	const chatName =  chat?.name || 'Чат'

	const clickHandler = (message: IMessage) => {
		setFindMessageDate(message.date)
		setFindMessageId(message.id)
		setActiveFind(false)
	}
	return(
		<div className={`messages__body__find-window ${active? 'active' : ''}`}>
			<div className="messages__body__find-window__wrapper">
			<span className="messages__body__find-window__title">Поиск сообщений c {chatName}</span>	
			{
				findMessages.length > 0 ?
				findMessages.map((message: IMessage) => {
					return (
						<div key={message.id} onClick={() => clickHandler(message)} className="messages__body__find-window__message-item">
							<div className="messages__body__find-window__time">{getTimeFromTimeStamp(+message?.date * 1000)}</div> 
							<div className="messages__body__find-window__message">{message?.caption}</div>
						</div>
					)
				})
				: <div style={{textAlign: "center"}}>Нет найденных сообщений</div>
			}
			</div>
		 </div>
	)
}