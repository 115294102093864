import React, { useEffect, useState } from "react";

import { Search } from '../Icons/Search';
import { Arrow } from '../Icons/Arrow';

export function ChatIcon({ edit, setSearchValue }: { edit: boolean, setSearchValue: React.Dispatch<React.SetStateAction<string>> }) {
  const [currentState, setCurrentState] = useState(false);

  useEffect(() => {
    setCurrentState(edit);
  }, [edit])

  return currentState ? <Arrow setSearchValue={setSearchValue} fillColor='#66757e' /> : <Search /> 
}
