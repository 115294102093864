import FileViewer from 'react-file-viewer';


export function MessageMedia({ media }: { media: string }) {
  const fileExtension = (/[.]/.exec(media)) ? /[^.]+$/.exec(media)?.toString() : undefined;

  if (media) {
    switch (fileExtension){
      case 'jpg': return <img src={media} alt='image' className='messages__media img' />;
      case 'jpeg': return <img src={media} alt='image' className='messages__media img' />;
      case 'png': return <img src={media} alt='image' className='messages__media img' />;
      case 'mp4': return (
        <video className='messages__media video' controls={true} loop={true}>
          <source src={media} />
        </video>
      );
      case 'oga': return <audio src={media} controls>Your browser does not support the <code>audio</code> element</audio>;
      case 'ptt': return <audio src={media} controls>Your browser does not support the <code>audio</code> element</audio>;
      case 'ogg': return <audio src={media} controls>Your browser does not support the <code>audio</code> element</audio>;
      case 'mp3': return <audio src={media} controls>Your browser does not support the <code>audio</code> element</audio>;
      case 'pdf': return (
        <FileViewer
          fileType={fileExtension}
          filePath={media}
        />
      );
      
      default: return <></>;
    }
  }

  return <></>;
}
