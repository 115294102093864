import React from 'react';

import { MessagesItem } from "./MessagesItem";

import { IMessage } from '../../types/IMessage';
import { IDateBlock } from '../../types/IDateBlock';

export function MessagesDateItem({ dateBlock, findMessageId}: { dateBlock: IDateBlock, findMessageId: string}) {
  const { date, messages } = dateBlock;
  return (
    <>
      <div className="messages__body__list__inner__date">
        <p className="messages__body__list__inner__date__text">{ date.split(' ')[0] }</p>
      </div>
      {
        messages && messages.map(message => {
          if (message.caption !== '' || message.body.length) {
            return <MessagesItem findMessageId={findMessageId} message={message} key={message.id} />
          }
        })
      }
    </>
  );
}