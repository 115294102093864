import { useEffect, useState } from "react";

import { IUserStore, useUserStore } from "../../store/user";

import { isEqual } from '../../helpers/equalsObjects';

import { IEnumerable } from '../../types/IEnumerable';
import { IUser } from "../../types/IUser";
import { getUsers } from "../../data/users";

import toastr from 'toastr';

export function UserSettings() {
  const user: IUser = useUserStore((state: IUserStore) => state.user);

  const [name, setName] = useState(user.username);
  const [phone, setPhone] = useState(user.phone);
  const [password, setPassword] = useState('');

  const toastrMessage: Array<string> = []
  
  const changeHandler = () => {
    const userWithChanges: IEnumerable = { ...user };

    [
      { name: 'username', value: name },
      { name: 'phone', value: phone },
      { name: 'password', value: password }
    ].forEach(({ name, value }: { name: string, value: string}) => {
      if (value) {
        userWithChanges[name] = value;
      }
    });
    if (!isEqual(user, userWithChanges)) {
      getUsers({
        ...userWithChanges,
        method: 'put'
      })
      .then((response: any) => {
          response.status === 'SUCCESS' 
            ? toastr.success(response, `Данные: "${toastrMessage.join(', ')}" успешно сохранены`, {closeButton: true})
            : toastr.error(response, 'Возникла проблема! Повторите позже', {closeButton: true});
      });;
    }
  }

  useEffect(() => {
    setName(user.username);
    setPhone(user.phone);
  }, [user]);

  useEffect(() => {
    if(user.username != name){
      toastrMessage.push('Имя пользователя')
    }
    if(user.phone != phone){
      toastrMessage.push('Телефон')
    }
    if(password != ''){
      toastrMessage.push('Пароль')
    }
  },[name, phone, password])

  return (
    <div className="settings__user">
      <h1 className="settings__user__title">Изменить данные</h1>
      <p className="settings__user__subtitle">Вы можете оставить поля не изменёнными и поменять только те, которые вам необходимо изменить.</p>

      <div className="settings__user__img__block"></div>
      <div className="settings__user__form">
        <h3 className="settings__user__form__title">Изменить имя пользователя</h3>
        <input 
          value={name}
          onChange={e => setName(e.target.value.trim())}
          placeholder="Введите имя" 
          type="text" 
          className="settings__user__form__input name" 
        />
        <h3 className="settings__user__form__title">Изменить номер телефона</h3>
        <input 
          value={phone}
          onChange={e => setPhone(e.target.value.trim())}
          placeholder="Введите номер телефона" 
          type="text" 
          className="settings__user__form__input phone " 
        />
        <h3 className="settings__user__form__title">Изменить пароль</h3>
        <input 
          value={password}
          onChange={e => setPassword(e.target.value.trim())}
          placeholder="Введите новый пароль" 
          type="text" 
          className="settings__user__form__input password" 
        />
      </div>

      <button onClick={changeHandler} className="settings__user__btn">Сохранить изменения</button>
    </div>
  );
}
