import React from 'react';

import { DocsRequestItem } from "./DocsRequestItem";

export function DocsRequestBlock({ requestParams }: { requestParams: any }) {
  return (
    <table className="documentation__item__body__request__params__block">
      <thead className="documentation__item__body__request__params__block__header">
        <tr>
          <th scope='col'>Параметр</th>
          <th scope='col'>Краткое описание</th>
          <th scope='col'>Тип параметра</th>
          <th scope='col'>Тип данных</th>
        </tr>
      </thead>
      <tbody className="documentation__item__body__request__params__block__body">
        {
          requestParams && requestParams.map((param: any) => {
            return <DocsRequestItem param={param} />;
          })
        }
      </tbody>
    </table>
  );
}