import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getStatus(params: any, method: string): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: 'api/state',
    method,
    params: { ...params },
    data: { ...params },
  }

  return new Promise((resolve, reject) => {
    try {
      basicQuery(options).then((response: any) => {
        const { data } = response;
        const { dataStates } = data;

        if (!dataStates)
          reject('Error in proccess getting data of dialogs!');

        resolve(data);
      });
    } catch (e) {
      console.log('Error: ', e);
    }
  });
}
