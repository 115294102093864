import React from "react";

export function Content({ 
  children, 
  sideBarActive 
}: { children: JSX.Element | JSX.Element[] | string | string[], sideBarActive: boolean }) {
  return (
    <div className={`wrapper ${sideBarActive ? 'small' : ''}`}>
      <div className="container">
        <div className="content">
          { children }
        </div>
      </div>
    </div>
  );
}