import React from 'react';
import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';
import { ISendFilterButtonsStore, useSendFilterButtonsStore } from '../../store/sendFilterButtons';
import { ISendFilterButton } from '../../types/ISendFilterButton';

export function SendFilterBtn({ 
  btn, 
  setFilterStatus 
}: { 
  btn: ISendFilterButton, 
  setFilterStatus: React.Dispatch<React.SetStateAction<string>> 
}) {
  const [inactivateButtons, setActive] = 
    useSendFilterButtonsStore((state: ISendFilterButtonsStore) => { 
      return [state.inactivateButtons, state.setActive] 
    });

  const clickHandler = (id: string | number) => {
    setFilterStatus(sendFilterStatuses[btn.value]);
    inactivateButtons();
    setActive(id);
  }

  return (
    <button className={`send__body__filter__block__btn ${btn.active ? 'active' : ''}`} onClick={() => clickHandler(btn.id)}>{ btn.name }</button>
  );
}