import React from 'react';
import { SendItem } from './SendItem';

// import { ISendItem } from '../../types/ISendItem';
import { ISendStore, useSendItemStore } from '../../store/sendItems';
import { compareStrings } from '../../helpers/compareStrings';
import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';
import {IUser} from '../../types/IUser';
import {IUserStore, useUserStore} from '../../store/user';

export function SendList({ 
  searchString,
  filterStatus,
  setModalActive, 
  setStartActive,
  setRemoveActive,
  setCurrentSendItemId
}: { 
  searchString: string
  filterStatus: string
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setStartActive: React.Dispatch<React.SetStateAction<boolean>>,
  setRemoveActive: React.Dispatch<React.SetStateAction<boolean>>,
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>>
}) {
  const user: IUser = useUserStore((state: IUserStore) => state.user);
  const sendItemsFromStore = useSendItemStore((state: ISendStore) => { 
    const items = state.sendItems.filter(item => item.type === user.dataType);
    const filteredItems = filterStatus !== sendFilterStatuses.all 
      ? items.filter(item => item.status === filterStatus) 
      : items

      return searchString.trim() ? filteredItems.filter(item => {
      return item.name.includes(searchString); 
    }) : filteredItems;
  });
  
    return (
    <div className="send__body__mailing__list">
      {
        sendItemsFromStore && sendItemsFromStore.sort((a, b) => compareStrings(a.datetime, b.datetime)).map((item, idx) => {
          return <SendItem 
            setCurrentSendItemId={setCurrentSendItemId}
            setStartActive={setStartActive} 
            setRemoveActive={setRemoveActive} 
            setModalActive={setModalActive} 
            item={item} 
            idx={idx} 
            key={item.id} 
          />
        })
      }
    </div>
  );
}
