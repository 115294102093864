import React, { useState, useEffect } from 'react';
import { getSendings } from '../../data/sendings';
import { ISendStore, useSendItemStore } from '../../store/sendItems';

import { ModalWindow } from '../ModalWindow';

export function RemoveWindow({ 
  active, 
  setActive, 
  currentSendItemId,
  setCurrentSendItemId
}: { 
  active: boolean, 
  setActive: React.Dispatch<React.SetStateAction<boolean>>, 
  currentSendItemId: string 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>>, 
}) {
  const [sendItemId, setSendItemId] = useState<string>('');

  const deleteSendItem = useSendItemStore((state: ISendStore) => state.deleteSendItem);

  const clickHandler = (remove: boolean) => {
    if (remove) {
      getSendings({ id: sendItemId }, 'delete').then((data: { status: string }) => {
        if (data.status === 'SUCCESS') {
          deleteSendItem(sendItemId);
        }
      });
    }
    setActive(false);
    setCurrentSendItemId('');
  }

  useEffect(() => {
    setSendItemId(currentSendItemId);
  }, [currentSendItemId]);

  return (
    <ModalWindow active={active} setActive={setActive}>
      <div className="send__remove__window">
        <h3 className="send__remove__window__title">Вы хотите удалить рассылку?</h3>

        <div className="send__remove__window__buttons">
          <button onClick={() => clickHandler(false)} className="send__remove__window__button cancel">Отменить</button>
          <button onClick={() => clickHandler(true)} className="send__remove__window__button confirm">Удалить</button>
        </div>
      </div>
    </ModalWindow>
  );
}