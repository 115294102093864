import axios from 'axios';

import { IQueryOptions } from '../types/IQueryOptions';

export function basicQuery(options: IQueryOptions): Promise<any> {
  const { path, to, method, params, data } = options;

  return new Promise(resolve => {
    try {
      resolve(axios.request({
        url: `${path}/${to}`,
        method, 
        params: { ...params },
        data: { ...data },
        timeout: 60 * 1000
      }))
    } catch (e) {
      console.log('Error: ', e);
    }
  })
}

