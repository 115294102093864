import { Link } from "react-router-dom";

import { LogOut } from "../LogOut/LogOut";

import { useUserStore, IUserStore } from '../../store/user';

export function HeaderUserOptions() {
  
  const { email, password } = useUserStore((state: IUserStore) => state.user);

  const clickHandler = () => {
     const params = {
      email: email,
      password: password
    }
    LogOut(params)
  }

  return (
    <ul className="header__user__options__list">
      <Link className="header__user__options__item" to='/settings'>Настройки</Link>
      <li onClick={clickHandler} className="header__user__options__item">Выйти</li>
    </ul>
  );
}
