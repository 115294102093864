import React from 'react';

import { SendButtons } from './SendButtons';

import { IEnumerable } from '../../types/IEnumerable';

export function SendItem({ 
  item, 
  idx, 
  setModalActive, 
  setStartActive,
  setRemoveActive,
  setCurrentSendItemId
}: { 
  item: any, 
  idx: number, 
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setStartActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setRemoveActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>> 
}) {
  const sendItemStatuses: IEnumerable = {
    created: 'Создана',
    work: 'В работе',
    error: 'Завершена с ошибкой',
    complete: 'Завершена',
  }

  return (
    <div className={`send__body__mailing__item ${item.status}`}>
      <div className="send__body__mailing__item__inner">

        <div className="send__body__mailing__item__left">
          <h3 className="send__body__mailing__item__idx"><b>{ idx + 1 }</b></h3>
          <h3 className="send__body__mailing__item__title">{ item.name }</h3> 
          <div className="send__body__mailing__item__date">{ item.datetime }</div>
        </div>
        <div className="send__body__mailing__item__center">
          <div className="send__body__mailing__item__count">Всего: { item.total || 0 }</div>
          <div className="send__body__mailing__item__send">Отправлено: { item.sended || 0 }</div>
        </div>
        <div className="send__body__mailing__item__status">{ sendItemStatuses[item.status] }</div>

        <SendButtons 
          sendItemId={item.id} 
          setCurrentSendItemId={setCurrentSendItemId}
          setStartActive={setStartActive} 
          setRemoveActive={setRemoveActive} 
          setModalActive={setModalActive} 
        />

      </div>
    </div>
  );
}
