import create from 'zustand';

import { IChat } from '../types/IChat';

export interface IChatStore {
  chats: IChat[]
  loaded: boolean
  setLoaded: (loaded: boolean) => void
  addChat: (chat: IChat) => void
  initialChats: (chats: IChat[]) => void
  putChat: (chat: IChat) => void
  deleteChat: (id: string) => void
}

export const useChatStore = create<IChatStore>()((set: any) => ({
  chats: [],
  loaded: false,
  setLoaded: (loaded: boolean) => set(() => ({ loaded })),
  addChat: (chat: IChat) => set((state: IChatStore) => {
    const chatsAsStringFormat: Set<string> = new Set(
      state.chats.map((chat: IChat) => JSON.stringify(chat))
    );
    chatsAsStringFormat.add(JSON.stringify(chat));

    return { 
      chats: Array.from(chatsAsStringFormat).map((chat: string) => {
        return JSON.parse(chat);
      })
    }
  }),
  initialChats: (chats: IChat[]) => set(() => ({ chats })),
  putChat: (chat: IChat) => set((state: IChatStore) => {
    const updatedChats = state.chats
      .filter((chatFromStore: IChat) => chatFromStore.id !== chat.id);

    return {
      chats: [...updatedChats, chat]
    }
  }),
  deleteChat: (id: string) => set((state: IChatStore) => {
    return {
      chats: state.chats
        .filter((chatFromStore: IChat) => chatFromStore.id !== id)
    }
  })
}));
