import React, { useState, useEffect } from "react";

import { IAuthData } from '../../types/IAuthData';

export function AuthForm({ auth, setAuthFormData, authFormData }: { auth: boolean, setAuthFormData: React.Dispatch<React.SetStateAction<IAuthData>>, authFormData: IAuthData }) {
  const [username, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  useEffect(() => {
    setAuthFormData({
      username, 
      email,
      phone,
      password,
      confirmPassword,
      isLogin: auth
    });
  }, [username, email, phone, password, confirmPassword, auth]);

  if (auth) {
    return (
      <div className="auth__body">
        <input 
          value={email}
          onChange={e => setEmail(e.target.value.trim())}
          placeholder="Введите email" 
          type="text" 
          className="auth__email" 
        />
        <input 
          value={password}
          onChange={e => setPassword(e.target.value.trim())}
          placeholder="Введите пароль"
          type="password" 
          className="auth__password" 
        />
      </div>
    );
  }
  return (
    <div className="auth__body">
      <input 
        value={username}
        onChange={e => setUserName(e.target.value)}
        placeholder="Введите имя" type="text" className="auth__name" 
      />
      <input
        value={email}
        onChange={e => setEmail(e.target.value.trim())}
        placeholder="Введите email" type="text" className="auth__email" 
      />
      <input 
        value={phone}
        onChange={e => setPhone(e.target.value.trim())}
        placeholder="Введите телефон" type="text" className="auth__phone" 
      />
      <input 
        value={password}
        onChange={e => setPassword(e.target.value.trim())}
        placeholder="Введите пароль" type="password" className="auth__password" 
      />
      <input 
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value.trim())}
        placeholder="Повторите пароль" type="password" className="auth__confirmpassword" 
      />
    </div>
  );
}
