import create from "zustand";

import { ISendItem } from "../types/ISendItem";

export interface ISendStore {
  sendItems: ISendItem[]
  initialSendItems: (sendItems: ISendItem[]) => void 
  addSendItem: (sendItem: ISendItem) => void
  changeSendItem: (id: string, sendItem: ISendItem) => void
  deleteSendItem: (id: string) => void
}

export const useSendItemStore = create<ISendStore>()((set: any) => ({
  sendItems: [],
  initialSendItems: (sendItems: ISendItem[]) => set(() => {
    return { sendItems }
  }),
  addSendItem: (sendItem: ISendItem) => set((state: ISendStore) => {
    if (state.sendItems.includes(sendItem)) {
      return { sendItems: state.sendItems };
    } else {
      return {
        sendItems: [...state.sendItems, sendItem]
      }
    }
  }),
  changeSendItem: (id: string, sendItem: ISendItem) => set((state: ISendStore) => {
    const newSendItems = state.sendItems.filter(item => item.id !== id)
    return {
      sendItems: [...newSendItems, { ...sendItem, id }]
    }
  }),
  deleteSendItem: (id: string) => set((state: ISendStore) => {
    return {
      sendItems: state.sendItems.filter(item => item.id !== id)
    }
  })
}));

