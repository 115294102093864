import { useState, useEffect } from 'react';

export function Send({ fillColor }: { fillColor: string }) {
  const [currentFillColor, setCurrentFillColor] = useState<string>('white');

  useEffect(() => {
    setCurrentFillColor(fillColor);
  }, [fillColor]);

  return (
    <svg 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      width="32.000000pt" height="32.000000pt" 
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
      fill={currentFillColor} 
      className="sidebar__nav__item__icon"
    >

    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M132 258 c-143 -46 -152 -60 -60 -93 69 -25 71 -27 96 -90 33 -83 50
    -71 99 70 23 66 42 128 42 138 1 26 -37 21 -177 -25z"/>
    </g>
    </svg>
  ); 
}