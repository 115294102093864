export function getTimeFromTimeStamp(timestamp: number): string {
  if (!timestamp) {
    return '';
  }

  const date = new Date(timestamp);

  const year = date.getFullYear(); 
  const month = "0" + (date.getMonth() + 1); 
  const day = "0" + date.getDate(); 

  const hours = "0" + date.getHours(); 
  const minutes = "0" + date.getMinutes(); 
  const seconds = "0" + date.getSeconds(); 

  return `${year}-${month.substr(-2)}-${day.substr(-2)} ${hours.substr(-2)}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
}