import { useState, useEffect, useRef } from "react";

import { MessageMedia } from './MessageMedia';

import { MessageArrow } from '../Icons/MessageArrow';

import { IMessage } from '../../types/IMessage';
import { prepareMessageCaption } from "../../helpers/prepareMessageCaption";

export function MessagesItem({ message, findMessageId }: { message: IMessage, findMessageId: string }) {
  const [messageCaption, setMessageCaption] = useState<string>('');
  const inArrowStyle = { right: '-.5rem', transform: 'rotateY(180deg)' }
  const outArrowStyle = { left: '-.5rem' }

  const fillColor = message.in ? '#d9fdd3' : '#fff';

  const messageFindedRef: React.RefObject<HTMLDivElement> = useRef(null);
  const messageFindItem = (message.id === findMessageId) ? 'message-finded' : ''

  useEffect(() => {
    const caption = prepareMessageCaption(message.caption)
    setMessageCaption(caption);
  }, []);


  useEffect(() => {
    const messageFinded = messageFindedRef.current;
    const isMessageFinded = messageFinded && messageFinded.classList.contains('message-finded');
  
    if (isMessageFinded) {
      messageFinded.scrollIntoView({ block: 'center'});
      const removeClassTimeout = setTimeout(() => {
        messageFinded.classList.remove('message-finded');
      }, 1700);
  
      return () => clearTimeout(removeClassTimeout);
    }
  }, [messageFindItem]);

  return (
    <>
      <div className={`messages__item__author ${message.in ? 'in' : 'out'}`}>{message.author}</div>
      <div ref={messageFindedRef} className={`messages__item ${message.in ? 'in' : 'out'} ${messageFindItem}` }>
        <div className="messages__item__arrow" style={message.in ? inArrowStyle : outArrowStyle}>
          <MessageArrow fillColor={fillColor} />
        </div>
        <div className="messages__item__body">
          <MessageMedia media={message.body} />
        </div>
        <div className="messages__item__caption">
          <p dangerouslySetInnerHTML={{ __html: messageCaption !== '0' ? messageCaption : '' }}></p>
        </div>
        <div className="messages__item__time">
          <p className="messages__item__time__text">{ message.date }</p>
        </div>
      </div>
    </>
  );
}
