import React, { useState } from 'react';

export function Change() {
  const [fillColor, setFillColor] = useState<string>('white');
  const [active, setActive] = useState<boolean>(false);

  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? '#ffc107' : 'white');
    setActive(hover);
  }

  return (
    <div className="send__body__mailing__item__button__icon">
      <div className={`send__body__mailing__item__button__icon__popup change ${active ? 'active' : ''}`}>
        <div className="send__body__mailing__item__button__icon__popup__text">Изменить параметры рассылки</div> 
        <div className="send__body__mailing__item__button__icon__popup__angle change"></div> 
      </div>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
        fill={fillColor}
        onMouseEnter={() => hoverHandler(true)} 
        onMouseLeave={() => hoverHandler(false)}
        >
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        stroke="none"
        fill={fillColor}
        >
        <path d="M205 616 c-65 -27 -165 -121 -165 -156 0 -40 24 -32 73 24 69 79 113
        101 207 101 87 0 133 -20 190 -81 23 -25 27 -32 11 -21 -26 17 -51 13 -51 -9
        0 -19 119 -106 139 -102 20 4 39 154 23 170 -16 16 -42 1 -42 -24 0 -20 -5
        -17 -47 24 -94 92 -225 121 -338 74z"/>
        <path d="M15 258 c-2 -7 -6 -47 -7 -88 -3 -69 -1 -75 17 -75 12 0 21 9 25 23
        5 22 7 21 49 -22 64 -65 127 -91 226 -90 66 0 89 5 131 26 56 30 144 120 144
        148 0 40 -24 32 -73 -24 -69 -79 -113 -101 -207 -101 -87 0 -133 20 -190 81
        -23 25 -27 32 -11 21 26 -17 51 -13 51 9 0 16 -115 104 -136 104 -8 0 -17 -6
        -19 -12z"/>
        </g>
      </svg>
    </div>
  );
}