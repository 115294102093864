import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Change } from '../Icons/Send/Change';
import { Start } from '../Icons/Send/Start';
import { Stop } from '../Icons/Send/Stop';
import { Delete } from '../Icons/Send/Delete';
import { Statistics } from '../Icons/Send/Staticstics';
import { ISendStore, useSendItemStore } from '../../store/sendItems';

import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';
import { ISendItem } from '../../types/ISendItem';

export function SendButtons({ 
  setModalActive, 
  setStartActive,
  setRemoveActive,
  setCurrentSendItemId,
  sendItemId
}: { 
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setStartActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setRemoveActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>> 
  sendItemId: string
}) {
  const [currentPrevSendItems, setCurrentPrevSendItems] = useState<ISendItem[]>([]);

  const item = useSendItemStore((state: ISendStore) => state.sendItems.find(item => item.id === sendItemId));
  const prevCurrentSendItems = useSendItemStore((state: ISendStore) => state.sendItems.filter(item => item.isCurrent));

  const changeSendItem = useSendItemStore((state: ISendStore) => state.changeSendItem);
  const [started, setStarted] = useState<boolean>(!!item && item.status === sendFilterStatuses.work);
  
  const changeItemHandler = () => {
    setCurrentSendItemId(sendItemId);
    setModalActive(true)
  }

  const startHandler = () => {
    setCurrentSendItemId(sendItemId);
    setStartActive(true)
  }

  const removeItemHandler = () => {
    setCurrentSendItemId(sendItemId);
    setRemoveActive(true)
  }

  const statisticHandler = () => {
    if (currentPrevSendItems.length) {
      currentPrevSendItems.forEach(prevItem => {
        changeSendItem(prevItem.id, { ...prevItem, isCurrent: false });
      });
    }
    if (item) {
      changeSendItem(item.id, { ...item, isCurrent: true });
    }
  }
  
  useEffect(() => {
    setStarted(item?.status === sendFilterStatuses.work)
  }, [item?.status]);

  // useEffect(() => {
  //   setCurrentPrevSendItems(prevCurrentSendItems);
  // }, [prevCurrentSendItems]);
 
  // useEffect(() => {
  //   setStarted(!!item && item.status === sendFilterStatuses.work);
  // }, [item]);

  return (
    <div className="send__body__mailing__item__buttons">
      <div className="send__body__mailing__item__button" onClick={startHandler}><Start started={started} /></div>
      <Link to={`/statistic?send=${item?.id}`} className="send__body__mailing__item__button"><Statistics statisticHandler={statisticHandler} /></Link>
      <div className="send__body__mailing__item__button" onClick={changeItemHandler}><Change /></div>
      <div className="send__body__mailing__item__button" onClick={removeItemHandler}><Delete /></div>
    </div>
  );
}
