import React, { useState, useEffect, useRef } from "react";

import { Dots } from "../Icons/Dots";

import { MessagesDateItem } from "./MessagesDateItem";

import userIcon from '../../assets/user.png'
import backgroundImage from '../../assets/bg-chat-tile-dark.png';
import leftArrow from '../../assets/left-arrow.png'

import { getMessages } from '../../data/message';

import { prepareMessages, prepareMessagesBottom } from '../../helpers/prepareMessages';

import { useUserStore, IUserStore } from '../../store/user';

import { IMessage, IMessageData } from "../../types/IMessage";
import { IChat } from "../../types/IChat";
import { IDateBlock } from '../../types/IDateBlock';
import { IUser } from '../../types/IUser';
import { MessageFind } from "./MessageFind";
import { MessageFindIcon } from "./MessageFindIcon";

export function MessagesList({ currentChat, setActiveChats }: {
  currentChat: IChat | undefined,
  setActiveChats: React.Dispatch<React.SetStateAction<boolean>>
}) {

  const [optionsActive, setOptionsActive] = useState<boolean>(false);
  const [dateBlocks, setDateBlocks] = useState<IDateBlock[]>([]);
  const [hasAvatar, setHasAvatar] = useState<boolean>();

  const [activeFind, setActiveFind] = useState<boolean>(false)
  const [scrollBottom, setScrollBottom] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('');
  const [findMessageDate, setFindMessageDate] = useState<string>('');
  const [findMessageId, setFindMessageId] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [findMessages, setFindMessages] = useState<Object>({})
  const [offsetBottom, setOffsetBottom] = useState<number>(0);
  const [limitBottom, setLimitBottom] = useState<number>(15);

  const [isAllGettingMessage, setIsAllGettingMessage] = useState<boolean>(true)
  const [allUserMessages, setAllUserMessages] = useState<IMessage[]>()
  const [idx, setIdx] = useState<number>(-1)

  const [more, setMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(!!currentChat?.id);
  const [limit, setLimit] = useState<number>(15);
  const [count, setCount] = useState<number>(15);
  const [offset, setOffset] = useState<number>(0);

  const listRef: React.RefObject<HTMLDivElement> = useRef(null);
  const messagesEndRef: React.RefObject<HTMLDivElement> = useRef(null);
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  const user: IUser = useUserStore((state: IUserStore) => state.user);

  const scrollHandler = (e: any) => {
    const { scrollTop } = e.target;

    const scrollToBottom = (listRef.current?.scrollHeight || 0) - (listRef.current?.clientHeight || 0) - scrollTop

    if (scrollTop < 400) {
      setFetching(true);
    } else {
      setFetching(false)
    }

    if (scrollToBottom < 200) {
      setScrollBottom(true)
    } else {
      setScrollBottom(false)
    }
  }

  const fetchMessages = () => {
    getMessages({
      limit,
      offset,
      type: user.dataType,
      chatId: currentChat?.id,
      orderBy: 'date',
    }, 'get').then((data: IMessageData) => {
      setDateBlocks(prepareMessages(dateBlocks, data.messages));

      setMore(data.more);
      setFetching(false);
      setOffset(offset + limit);
      setCount(data.count)

      if (offset < 15) {
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView();
        }, 10);
      }
    });
  }

  useEffect(() => {
    setDateBlocks([]);
    setLimit(15);
    setLimitBottom(15);
    setOffset(0);
    setMore(true);
    setFetching(!!currentChat?.id);
    setHasAvatar(!!(currentChat?.image && currentChat.image !== 'undefined'));

    setIsAllGettingMessage(true)
    setAllUserMessages([])
    setScrollBottom(false)
    setActiveFind(false)
    setSearchValue('')
    setFindMessages({})
  }, [currentChat]);

  useEffect(() => {
    if (fetching && more && isAllGettingMessage) {
      fetchMessages();
    }

    if(fetching && more && !isAllGettingMessage){
      showMessagesTop()
      setOffset(offset + limit);

      if(offset > count){
        setMore(false)
      }
    }
  }, [fetching, more]);

  useEffect(() => {
    if (searchValue !== '' && searchValue.length > 1) {
      const timer = setTimeout(() => {
        setSearching(true)
        clearInterval(timer)                                                                             // поисковая строка
      }, 1000)

      setSearching(false)

      isAllGettingMessage && getMessages({                                                               // получение всех сообщений пользователя
        type: user.dataType,
        chatId: currentChat?.id,
        orderBy: 'date',
        limit: count,
      }, 'get').then((data: IMessageData) => {
        setIsAllGettingMessage(false)
        setAllUserMessages(data.messages)
      })
    }
  }, [searchValue]);


  useEffect(() => {
    const messages = (searchValue !== '')
      ? allUserMessages?.filter(message => message?.caption.toLowerCase()
        .includes(searchValue.toLowerCase()))                                                            // поиск по сообщениям
      : ''

    setFindMessages(messages || {})
    setSearching(false)

  }, [searching])

  useEffect(() => {
    const idx = Number(allUserMessages?.findIndex(message => message.date === findMessageDate))  // индекс сообщения
    setIdx(idx)
    setDateBlocks([]);
  }, [findMessageDate])

  useEffect(() => {
    if (idx >= 0) {

      const findMessages = idx > 15 ? allUserMessages?.slice(idx - 15, idx + 15) || []
                                    : allUserMessages?.slice(0, idx + 15) || []

      setDateBlocks(prepareMessages(dateBlocks, findMessages));

      setOffset(idx + limit)

      if (idx - 15 > 15) {
        setOffsetBottom(idx - 30)
        setLimitBottom(15)
      } else {
        setOffsetBottom(0)
        setLimitBottom(idx - 15)
      }

      setScrollBottom(true)
      setSearchValue('')
      setFindMessages({})
    }

  }, [idx])

  useEffect(() => {                                                                             // отображение  сообщений по скролу вниз 
    idx >= 0 && scrollBottom && limitBottom > 0 && showMessagesBottom()

    if (offsetBottom > 15) {
      setOffsetBottom(offsetBottom - limitBottom)
    } else {
      setOffsetBottom(0)
      setLimitBottom(offsetBottom)
    }
  }, [scrollBottom])

  const showMessagesTop = () => {                                                               // загрузка сообщений по скролу вверх
    const nextMessages = allUserMessages?.slice(offset, offset + 15)

    return setDateBlocks(prepareMessages(dateBlocks, nextMessages || []));
  }

  const showMessagesBottom = () => {                                                                  // отображение  сообщений по скролу вниз 
    const prevMessages = offsetBottom > 15 ? allUserMessages?.slice(offsetBottom, offsetBottom + limitBottom)
                                           : allUserMessages?.slice(offsetBottom, limitBottom - offsetBottom)

    return setDateBlocks(prepareMessagesBottom(dateBlocks, prevMessages || []));

  }

  const clickHandlerFindMessageButton = () => {
    setActiveFind(prev => !prev)

    setTimeout(() => {
      if (inputRef.current && !activeFind) {
        inputRef.current.focus();
      }
    }, 350)
  }

  useEffect(() => {
    listRef.current?.addEventListener('scroll', scrollHandler);
    return function () {
      listRef.current?.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  return (
    <div className="messages__list">
      <div className="messages__header">
        <div className="messages__header__left">
          <div className="messages__header__left__img__block">
            <img className="arrow-left" src={leftArrow} alt="arrow-left" onClick={() => setActiveChats(true)} />
            <img
              src={hasAvatar ? currentChat?.image : userIcon} alt="avatar"
              className={`messages__header__left__img ${hasAvatar ? '' : 'invert'}`}
            />
          </div>
          <div className="messages__header__left__username">{currentChat?.name !== 'undefined' ? currentChat?.name : 'Чат'}</div>
        </div>

        <div className="messages__header__right">
          <div onClick={() => clickHandlerFindMessageButton()} className="messages__header__right__search">
            <input
              ref={inputRef}
              value={searchValue}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setSearchValue(e.target.value)}
              className={`messages__header__right__input ${activeFind ? 'active' : ''}`}
            />
            <MessageFindIcon active={activeFind} currentChat={currentChat} />
          </div>
          <div onClick={() => setOptionsActive(!optionsActive)} className="messages__header__right__options">
            <Dots fillColor="#54656f" />
            <div className={`messages__header__right__options__menu ${optionsActive ? 'active' : ''}`}>
              <div className="messages__header__right__options__menu__inner">
                <p className="messages__header__right__options__menu__item">Данные контакта</p>
                <p className="messages__header__right__options__menu__item">Данные контакта</p>
                <p className="messages__header__right__options__menu__item">Данные контакта</p>
                <p className="messages__header__right__options__menu__item">Данные контакта</p>
                <p className="messages__header__right__options__menu__item">Данные контакта</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="messages__body" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <MessageFind active={activeFind} setActiveFind={setActiveFind} setFindMessageDate={setFindMessageDate} setFindMessageId={setFindMessageId} chat={currentChat} findMessages={findMessages} />
        <div ref={listRef} className="messages__body__list">
          <div className="messages__body__list__inner">
            {
              dateBlocks && dateBlocks.map((dateBlock: IDateBlock) => {
                return <MessagesDateItem findMessageId={findMessageId} dateBlock={dateBlock} key={dateBlock.date} />
              })
            }
            <span ref={messagesEndRef}></span>
          </div>
        </div>
      </div>
    </div>
  );
}
