import React, { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { ISendStore, useSendItemStore } from '../../store/sendItems';

import { ModalWindow } from '../ModalWindow';
import { SendWindowFilePreview } from './SendWindowFilePreview';

import { ISendItemValues } from '../../types/ISendItemValues';

import { getTimeFromTimeStamp } from '../../helpers/getTimeFromTimestamp';
import { IUserStore, useUserStore } from '../../store/user';
import { getSendings } from '../../data/sendings';
import { sendFilterStatuses } from '../../helpers/sendFilterStatuses';
import { IMedia } from '../../types/IMedia';

export function SendWindow({ 
  active, setActive, currentSendItemId, setCurrentSendItemId
}: { 
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  currentSendItemId: string 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>>
}) {
  // Local State--------------------------------------------
  const [sendItemValues, setSendItemValues] = useState<ISendItemValues>({
    name: '',
    phones: '',
    message: ''
  });
  const [currentMedia, setCurrentMedia] = useState<IMedia>();
  const [currentTotal, setCurrentTotal] = useState<number>(sendItemValues.phones.split('\n').length);
  // End Local State--------------------------------------------

  // Store Variables--------------------------------------------
  const user = useUserStore((state: IUserStore) => state.user);
  const currentSendItem = useSendItemStore((state: ISendStore) => {
    return state.sendItems.find(item => item.id === currentSendItemId); 
  });
  const [changeSendItem, addSendItem] = useSendItemStore((state: ISendStore) => {
    return [state.changeSendItem, state.addSendItem];
  });
  // End Store Variables--------------------------------------------

  // Handlers--------------------------------------------
  const clickHandler = (save: boolean) => {
    if (!save) {
      return setActive(false);
    }

    const { name, phones, message } = sendItemValues;
    if (!name.trim() || !phones.trim() || !message.trim()) {
      return alert('Поля названия, телефонов и сообщения не могут быть пустыми! Пожалуйста, заполните их и попробуйте создать рассылку снова');
    }

    let currentProcessId = '';
    if (currentSendItem) {
      currentProcessId = currentSendItem?.status !== sendFilterStatuses.complete ? currentSendItem.processId : '';
    }

    const newSendItem = {
      id: currentSendItem ? currentSendItem.id : uniqid(),
      processId: currentProcessId,
      datetime: getTimeFromTimeStamp(+Date.now().toString()),
      name: name.trim(),
      phones: phones.trim(),
      text: message.trim(),
      sended: 0,
      total: currentTotal,
      media: currentMedia?.base64 || currentSendItem?.media || '',
      mediaName: currentMedia?.name || currentSendItem?.mediaName || '',
      mediaExtension: currentMedia?.type || currentSendItem?.mediaExtension || '',
      status: sendFilterStatuses.created,
      type: user.email,
    }

    getSendings(newSendItem, currentSendItem ? 'put' : 'post').then(() => {
      if (currentSendItem) {
        changeSendItem(currentSendItem.id, { 
          ...currentSendItem,
          name: name.trim(),
          phones: phones.trim(),
          text: message.trim(),
          status: sendFilterStatuses.created,
          media: currentMedia?.base64 || currentSendItem?.media || '',
          mediaName: currentMedia?.name || currentSendItem?.mediaName || '',
          mediaExtension: currentMedia?.type || currentSendItem?.mediaExtension || '',
          total: currentTotal,
          sended: 0,
          processId: currentProcessId,
        });
      } else {
        addSendItem({ ...newSendItem, isCurrent: false });
      }
      setSendItemValues({ name: '', phones: '', message: '' });
      setActive(false);
    });
  }

  const fileHandler = (e: any) => {
    const file = e.target.files[0];
    const reader: FileReader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setCurrentMedia({
          name: file.name,
          type: file.type,
          size: file.size,
          base64: reader.result as string || ''
        });
      };
    }
  }
  // End Handlers--------------------------------------------
  
  // Effects--------------------------------------------
  useEffect(() => {
    if (!active) {
      setCurrentSendItemId(''); 
    }
  }, [active]);

  useEffect(() => {
    if (currentSendItem) {
      if (!currentMedia?.base64) {
        setCurrentMedia({
          name: currentSendItem.mediaName,
          type: 'image',
          size: 0,
          base64: currentSendItem.media
        });
      }

      setSendItemValues({
        name: currentSendItem.name,
        phones: currentSendItem.phones,
        message: currentSendItem.text,
      });
    }
  }, [currentSendItem]);

  useEffect(() => {
    const total = Array.from(new Set(sendItemValues.phones.split('\n').map(i => i.split(',')[0]))).length;
    setCurrentTotal(total);
  }, [sendItemValues]);
  // End Effects--------------------------------------------

  return (
    <ModalWindow active={active} setActive={setActive}>
      <div className="send__window">
        <div className="send__window__header">
          <h2 className="send__window__title">{ currentSendItemId ? 'Изменить параметры рассылки' : 'Новая рассылка' }</h2>
        </div>
        <div className="send__window__name">
          <input 
            placeholder='Введите название рассылки' 
            onChange={e => setSendItemValues({ ...sendItemValues, name: e.target.value })}
            value={sendItemValues.name || ''} 
            type="text" 
            className="send__window__name__input" 
          />
        </div>
        <div className="send__window__body">
          <div className="send__window__body__phones">
            <h3 className="send__window__body__phones__title">Введите номера телефонов</h3>
            <textarea 
              value={sendItemValues.phones || ''} 
              onChange={e => setSendItemValues({ ...sendItemValues, phones: e.target.value })}
              placeholder="791328791&#10;791328791" 
              name="phones" 
              className="send__window__body__phones__field"
            ></textarea>
          </div>
          <div className="send__window__body__message">
            <h3 className="send__window__body__message__title">Введите Сообщения для отправки</h3>
            <textarea 
              value={sendItemValues.message || ''} 
              onChange={e => setSendItemValues({ ...sendItemValues, message: e.target.value })}
              placeholder="Привет" 
              name="message" 
              className="send__window__body__message__field"
            >{ currentSendItem?.text || '' }</textarea>
          </div>
          <div className="send__window__body__media">
            <div className="file-input">
              <input onChange={fileHandler} type="file" id="file" className="file" />
              <label htmlFor="file">{ currentMedia?.name ? 'Вы можете выбрать другой файл' : 'Выберите файл для отправки' }</label>
            </div>
            <SendWindowFilePreview file={currentMedia} setCurrentMedia={setCurrentMedia} />
          </div>
          {/* <div className="send__window__body__time">{ currentSendItem?.datetime }</div> */}
        </div>
        <div className="send__window__footer">
          <div className="send__window__buttons">
            <button className="send__window__button cancel" onClick={() => clickHandler(false)}>Отменить</button>
            <button className="send__window__button save" onClick={() => clickHandler(true)}>Сохранить</button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
}
