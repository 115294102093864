import { useState } from 'react';
import { Link } from 'react-router-dom';

export function NavItem(
  { 
    icon, 
    target,
    name,
    path
  }: { 
    icon: ({ fillColor }: { fillColor: string }) => JSX.Element, 
    name: string,
    target: string,
    path: string
  }
) {
  const [fillColor, setFillColor] = useState<string>('white');
  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? '#00a884' : 'white');
  }
  
  return (
    <Link to={path} target={target}
      className="sidebar__nav__item"
      onMouseEnter={() => hoverHandler(true)} 
      onMouseLeave={() => hoverHandler(false)}
    >
      <div className="sidebar__nav__item__icon__block">
        { icon({ fillColor }) }
      </div>
      <p className="sidebar__nav__item__name">{name}</p>
    </Link>
  );
}