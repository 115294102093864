import { basicQuery } from './query';

import { IQueryOptions } from '../types/IQueryOptions';

export function getAuth(params: any): Promise<any> {
  const options: IQueryOptions = {
    path: '',
    to: `api/auth/${params.isLogin ? 'login' : 'register'}`,
    method: 'post',
    params: {},
    data: params
  }

  return new Promise((resolve, reject) => {
    basicQuery(options).then((response: any) => {
      const { data } = response;

      resolve(data);
    })
      .catch(reject)
  })
}

