import { useState } from "react";

import { DocsRequestBlock } from "./DocsRequestBlock";

import { IDocs } from "../../types/IDocs";

export function DocsItem({ docItem }: { docItem: IDocs }) {
  const [active, setActive] = useState<boolean>(false);

  return (
    <div className="documentation__item">

      <div className={`documentation__item__query ${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
        <div className={`documentation__item__query__type ${docItem.value.query}`}>
          <p className="documentation__item__query__type__text">{ docItem.value.query }</p>
        </div>
        <div className="documentation__item__query__text">
          <p className="documentation__item__query__name">{ docItem.value.path }</p>
          <p className="documentation__item__query__description">{ docItem.value.description }</p>
        </div>
      </div>

        <div className={`documentation__item__body ${active ? 'active' : ''}`}>
          <div className="documentation__item__body__request">
            <h3 className="documentation__item__body__request__title">Параметры</h3>
            <div className="documentation__item__body__request__params">
              <DocsRequestBlock requestParams={docItem.value.request} key={docItem.value.id} />
            </div>
          </div>

          <div className="documentation__item__body__response">
            <h3 className="documentation__item__body__response__title">Пример успешного ответа (status 200)</h3>
            <pre className="codeblock">
              { 
                JSON.stringify(docItem.value.response.example, undefined, 2) 
              }
            </pre>
          </div>
        </div>
    </div>
  );
}
