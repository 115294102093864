import { useState, useEffect } from 'react';
import { useDocsStore, IDocsStore } from '../../store/docs';

import { DocsItem } from "./DocsItem";

import { IDocs } from '../../types/IDocs';
import { compareStrings } from '../../helpers/compareStrings';

export function DocsList() {
  const [currentDocs, setCurrentDocs] = useState<IDocs[]>([]);

  const docs = useDocsStore((state: IDocsStore) => state.docs);

  useEffect(() => {
    setCurrentDocs(docs.sort((doc1: IDocs, doc2: IDocs) => compareStrings(doc1.id, doc2.id)));
  }, [docs]);

  return (
    <>
      <div className="documentation__header">
        <h2 className="documentation__title">Документация к публичному API</h2>
        <p className="documentation__subtitle">Ниже описаны примеры запросов для пользования сервисом рассылки</p>
        <p className="documentation__text">Можно передавать не все указанные параметры</p>
        <p className="documentation__text">Фильтрация будет идти только по переданным</p>
      </div>
      <div className="documentation__list">
        {
          currentDocs && currentDocs.map(docItem => {
            return <DocsItem docItem={docItem} key={docItem.id} />
          })
        }
      </div>
    </>
  );
}
