import { useState } from "react";
import { IUserStore, useUserStore } from "../../store/user";
import { IUser } from "../../types/IUser";

import { getUsers } from "../../data/users";

import { AuthorizationQr } from "./AuthorizationQr";

export function Authorization() {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [qr, setQr] = useState<string>(currentUser?.qr || '');

  const user = useUserStore((state: IUserStore) => state.user);
  const authorizeUser: (user: IUser) => void = useUserStore((state: IUserStore) => state.authorize);

  const changeHandler = () => {
    getUsers({
      ...user,
      password: '',
      needQr: 1,
      method: 'put'
    });

    const interval = setInterval(() => {
      getUsers({}).then((data: { status: string, user: IUser }) => {
        if (data.user.qr) {
          authorizeUser(data.user);
          setCurrentUser(user);
          setQr(user.qr);
          clearInterval(interval);

          getUsers({
            ...user,
            qr: '',
            password: '',
            needQr: 0,
            method: 'put'
          });
        }
      });
    }, 1000);
  }

  return (
    <div className="settings__auth">
      <h1 className="settings__auth__title">Зарегистрироваться в whataspp</h1>

      <div className="settings__auth__inner">
        <ol className="settings__auth__instruction">
          <li className="settings__auth__instruction__item">Нажмите на кнопку генерации qr-кода</li>
          <li className="settings__auth__instruction__item">Подождите несколько секунд пока система генерирует qr-код</li>
          <li className="settings__auth__instruction__item">Отсканируйте сгенерированный qr-код в приложении whatsapp</li>
          <li className="settings__auth__instruction__item">Подождите пока система авторизует вас в приложении whatsapp</li>
          <li className="settings__auth__instruction__item">После завершения можете приступать к использованию нашего сервиса!</li>
        </ol>

        <div className="settings__auth__qr__block">
          <AuthorizationQr authorize={currentUser?.authorize || 0} qr={qr} />
          <button onClick={changeHandler} className="settings__auth__qr__btn">Сгенерировать qr-код</button>
        </div>
      </div>
    </div>
  );
}
