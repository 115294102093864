import React, { useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';

import { SendButtons } from './Send/SendButtons';

import { StatisticPerson } from './StatisticPerson';
import { StatisticLoader } from './StatisticLoader';

import { IPerson } from '../types/IPerson';
import { ISendItem } from '../types/ISendItem';
import { ISendItemData } from '../types/ISendItem';

import { sendFilterStatuses } from '../helpers/sendFilterStatuses';

import { getSendings } from '../data/sendings';
import { getPeople } from '../data/people';

export function SendStatisticComponent({ 
  setModalActive, 
  setStartActive,
  setRemoveActive,
  setCurrentSendItemId
}: { 
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setStartActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setRemoveActive: React.Dispatch<React.SetStateAction<boolean>>, 
  setCurrentSendItemId: React.Dispatch<React.SetStateAction<string>> 
}) {

  const [searchParams, setSearchParams] = useSearchParams();

  const getUrlParamsId = searchParams.get('send')

  const [sendPeople, setSendPeople] = useState<IPerson[]>([]);
  const [sendItem, setSendItem] = useState<ISendItem[]>([])

  const [limit, setLimit] = useState<number>(40);
  const [offset, setOffset] = useState<number>(0);

  const [fetching, setFetching] = useState<boolean>(true);
  const [more, setMore] = useState<boolean>(true);
  const [load, setLoad] = useState<boolean>(true);

  const currentSendItem = sendItem?.find(item => item.id === getUrlParamsId)

  const scrollHandler = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target.scrollingElement;
    if (more && scrollHeight - scrollTop - clientHeight < 1) {
        setFetching(true);
    }
  }

  useEffect(() => {
    getSendings({}, 'get').then((data: ISendItemData) => {
      setSendItem(data.sendings);
      setLoad(false)
    });
  }, []);
  
  useEffect(() => {
    if (fetching) {
      if (currentSendItem) {
        getPeople({ sendingId: getUrlParamsId, offset, limit, orderBy: 'datetime'}, 'get').then(data => {
          const { people } = data
          const newPeople = [...sendPeople, ...people]
          if (people.length) {
            setSendPeople(newPeople);
          }
          setMore(data.more);
          setOffset(offset + limit);
          setLimit(limit)
          setFetching(false)
        });
      }
    }
  }, [fetching, currentSendItem]);
  
  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return function() {
      document.removeEventListener('scroll', scrollHandler);
    }
  });

  if (!currentSendItem) {
    if(load){
      return (
        <div className="send__statistic">
          <div className="send__statistic__header">
            <h2 className="send__statistic__title">Идет загрузка ...</h2>
            <p className="send__statistic__subtitle">Пожалуйста, подождите</p>
          </div>
        </div>
        );  
    }
    
    return (
    <div className="send__statistic">
      <div className="send__statistic__header">
        <h2 className="send__statistic__title">Прозошла ошибка</h2>
        <p className="send__statistic__subtitle">Повторите попытку позже</p>
      </div>
    </div>
    );
  }

  return (
    <div className="send__statistic">
      <div className="send__statistic__header">
        <h2 className="send__statistic__title">Подбробная информация о расслыке</h2>
        <p className="send__statistic__subtitle">Информация об отправленных сообщениях по рассылке</p>
      </div>

      <div className="send__statistic__body">
        <div className="send__statistic__mailing__info">
          <div className="send__statistic__mailing__info__header">
            <div className="send__statistic__mailing__info__header__item">
              <div className={`send__body__mailing__item__inner statistic ${sendFilterStatuses[currentSendItem.status]}`}>

                <div className="send__body__mailing__item__left">
                  {/* <h3 className="send__body__mailing__item__idx"><b>{ currentSendItem.id }</b></h3> */}
                  <h3 className="send__body__mailing__item__title">{ currentSendItem.name }</h3> 
                  <div className="send__body__mailing__item__date">{ currentSendItem.datetime }</div>
                </div>
                <div className="send__body__mailing__item__center">
                  <div className="send__body__mailing__item__count">Всего: { currentSendItem.total }</div>
                  <div className="send__body__mailing__item__send">Отправлено: { currentSendItem.sended }</div>
                </div>

                <SendButtons
                  sendItemId={currentSendItem.id} 
                  setCurrentSendItemId={setCurrentSendItemId}
                  setStartActive={setStartActive} 
                  setRemoveActive={setRemoveActive} 
                  setModalActive={setModalActive} 
                />

              </div>
              <div className="send__statistic__mailing__info__header__item__text__block">
                <h2 className="send__statistic__mailing__info__header__item__title">Сообщение</h2>
                <p className="send__statistic__mailing__info__header__item__text">
                  { currentSendItem.text }
                </p>
              </div>
            </div>
          </div>
          <div className="send__statistic__mailing__info__footer">
            <h2 className="send__statistic__mailing__info__footer__title">Данные об отправке</h2>

            <table className="send__statistic__mailing__info__footer__table">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Имя</th>
                  <th>Номер Телефона</th>
                  <th>Статус Отправки</th>
                  <th>Время отправки</th>
                </tr>
              </thead>
              <tbody>
                {
                  sendPeople && sendPeople
                  .map((sendPerson, idx) => {
                    return <StatisticPerson person={sendPerson} idx={idx} key={sendPerson.id} />
                  })
                }
              </tbody>
            </table>
            {
              fetching && <StatisticLoader />
            }
          </div>
        </div>
      </div>

    </div>
  );
}
