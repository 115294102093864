import React, { useState } from 'react';

import { Header } from '../components/Header/Header';
import { SideBar } from '../components/SideBar/SideBar';
import { Content } from '../components/Content';
import { SendStatisticComponent } from '../components/Statistic';
import { SendWindow } from '../components/Send/SendWindow';
import { StartWindow } from '../components/Send/StartWindow';
import { RemoveWindow } from '../components/Send/RemoveWindow';
import { Auth } from '../components/Auth/Auth';

import { useUserStore, IUserStore } from '../store/user';

export function SendStatistic() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [startActive, setStartActive] = useState<boolean>(false);
  const [removeActive, setRemoveActive] = useState<boolean>(false);
  const [currentSendItemId, setCurrentSendItemId] = useState<string>('');
  const user = useUserStore((state: IUserStore) => state.user);

  if(!user.id){
    return <Auth />
  }
  
  return (
    <>
      <Header sideBarActive={sideBarActive} user={user} />
      <SideBar setSideBarActive={setSideBarActive} />
      <Content sideBarActive={sideBarActive} >
        <SendStatisticComponent setCurrentSendItemId={setCurrentSendItemId} setModalActive={setModalActive} setStartActive={setStartActive} setRemoveActive={setRemoveActive} />
      </Content>
      <SendWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId}  active={modalActive} setActive={setModalActive} />
      <StartWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId} active={startActive} setActive={setStartActive} />
      <RemoveWindow setCurrentSendItemId={setCurrentSendItemId} currentSendItemId={currentSendItemId} active={removeActive} setActive={setRemoveActive} />
    </>
  );
}
