import React, { useState, useEffect } from 'react';

export function Chat({ fillColor }: { fillColor: string }) {
  const [currentFillColor, setCurrentFillColor] = useState<string>('white');

  useEffect(() => {
    setCurrentFillColor(fillColor);
  }, [fillColor]);

  return (
    <svg 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      width="32.000000pt" height="32.000000pt" 
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
      fill={currentFillColor} 
      className="sidebar__nav__item__icon"
    >
      <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
       stroke="none">
      <path d="M14 287 c-2 -7 -3 -56 -2 -108 l3 -94 28 -3 c23 -3 27 -8 27 -33 0
      -36 4 -36 49 -1 30 22 49 28 111 32 l75 5 0 105 0 105 -143 3 c-112 2 -144 0
      -148 -11z m276 -97 l0 -90 -69 0 c-60 0 -74 -3 -100 -26 l-31 -26 0 26 c0 22
      -4 26 -30 26 l-30 0 0 90 0 90 130 0 130 0 0 -90z"/>
      <path d="M70 210 c0 -6 37 -10 90 -10 53 0 90 4 90 10 0 6 -37 10 -90 10 -53
      0 -90 -4 -90 -10z"/>
      <path d="M70 170 c0 -5 23 -10 50 -10 28 0 50 5 50 10 0 6 -22 10 -50 10 -27
      0 -50 -4 -50 -10z"/>
      </g>
    </svg>
  ); 
}