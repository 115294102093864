import { getLogOut } from "../../data/logOut";
import toastr from 'toastr';

export function LogOut(params: Object) {
  
  getLogOut(params).then((response: any) => {
    if (response.status && response.message) {
      response.status === 'SUCCESS' 
        ? toastr.success(response.message, '', {closeButton: true})
        : toastr.error(response.message, 'Возникла проблема!', {closeButton: true});
    }
    setTimeout(() => {
      window.location.reload();
   },100)
  });
    
}