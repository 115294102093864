import React, { useEffect, useRef, useState } from 'react';

import { ChatItem } from './ChatItem';
import { ChatIcon } from './ChatIcon';

import { ReceivingStatus } from './ReceivingStatus';

import { getChats } from '../../data/chat';

import { IChat } from '../../types/IChat';

import { prepareChats } from '../../helpers/prepareChats';

import { useUserStore, IUserStore } from '../../store/user';
import { useChatStore, IChatStore } from '../../store/chat';

export function ChatList({ setCurrentChat, currentChat, activeChats, setActiveChats }: 
  { setCurrentChat: React.Dispatch<React.SetStateAction<IChat | undefined>>, currentChat: IChat | undefined,
    activeChats: boolean, setActiveChats: React.Dispatch<React.SetStateAction<boolean>> } 
) {
  const [edit, setEdit] = useState(false);
  const [chats, setChats] = useState<IChat[]>([]);
  const [more, setMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(15);
  const [offset, setOffset] = useState<number>(0);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const inputRef: React.RefObject<HTMLInputElement> | null = useRef(null);
  const listRef: React.RefObject<HTMLInputElement> | null = useRef(null);

  const user = useUserStore((state: IUserStore) => state.user);
  const initialChats = useChatStore((state: IChatStore) => state.initialChats);

  const scrollHandler = (e: any) => {
    const { scrollHeight, scrollTop } = e.target;

    if (more && scrollHeight - scrollTop < 780) {
      setFetching(true);
    }
  }

  const getCurrentChats = async (params: any) => {
    return await getChats(params, 'get').then((data) => {
      const chatsToPreparing: IChat[] = data.chats
        .map((chat: IChat) => ({ ...chat, lastMessageTime: +chat.lastMessageTime }));

      if (!searching) {
        chatsToPreparing.push(...chats);
      } 
      const preparedChats = prepareChats(chatsToPreparing);

      setMore(chats.length < data.count);
      setFetching(false);
      setSearching(false);
      return preparedChats;
    });
  }

  useEffect(() => {
    if (searching && user.id) {
      setEdit(!!searchValue)
      const params = {
        limit: 15, 
        offset: 0, 
        type: user.email, 
        orderBy: 'lastMessageTime', 
        searchString: {
          name: 'name',
          value: searchValue.trim()
        } 
      }

      getCurrentChats(params).then((preparedChats) => {
        initialChats(preparedChats);
        setChats(preparedChats);
      });
    }
  }, [searching]);

  useEffect(() => {
    if (fetching && user.id) {
      const params = {
        limit, offset, type: user.email, orderBy: 'lastMessageTime'
      }
      getCurrentChats(params).then((preparedChats) => {
        setOffset(offset + limit);
        initialChats(preparedChats);
        setChats(preparedChats);
      });
    }
  }, [fetching]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSearching(true)
      clearInterval(timer)
    }, 300)
  }, [searchValue]);

  useEffect(() => {
    listRef.current?.addEventListener('scroll', scrollHandler);
    return function() {
      listRef.current?.removeEventListener('scroll', scrollHandler);
    }
  }, []);

  return (
    <div className={`chat__list ${activeChats ? '' : 'no-active'}`}>
      <div className="chat__list__header">
        <div className="chat__list__header__inner">
          <ChatIcon setSearchValue={setSearchValue} edit={edit} />

          <input 
            ref={inputRef} 
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)} 
            className="chat__list__header__input" 
          />
        </div>
      </div>
      <div className="chat__list__data__state">
        <ReceivingStatus />
      </div>
      <div ref={listRef} className="chat__list__inner">
        {
          chats && chats.map(chat => {
            return <ChatItem currentChat={currentChat} setCurrentChat={setCurrentChat} chat={chat} key={chat.id} setActiveChats={setActiveChats}/>
          })
        }
      </div>
    </div>
  );
}
