import React, { useState, useEffect } from 'react';

export function Dots({ fillColor }: { fillColor: string }) {
  const [currentFillColor, setCurrentFillColor] = useState<string>('white');

  useEffect(() => {
    setCurrentFillColor(fillColor);
  }, [fillColor]);

  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
    fill={currentFillColor}
    >

      <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path d="M280 560 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34
      60 60 0 11 -9 29 -20 40 -11 11 -29 20 -40 20 -11 0 -29 -9 -40 -20z"/>
      <path d="M280 360 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34
      60 60 0 11 -9 29 -20 40 -11 11 -29 20 -40 20 -11 0 -29 -9 -40 -20z"/>
      <path d="M280 160 c-11 -11 -20 -29 -20 -40 0 -26 34 -60 60 -60 26 0 60 34
      60 60 0 26 -34 60 -60 60 -11 0 -29 -9 -40 -20z"/>
      </g>
    </svg>

  ); 
}
