import { IChat } from "../types/IChat";
import { IEnumerable } from '../types/IEnumerable';
import { compareStrings } from "./compareStrings";
import { getTimeFromTimeStamp } from "./getTimeFromTimestamp";

function chatsComparer(chatA: IChat, chatB: IChat): number {
  return +chatA.lastMessageTime - +chatB.lastMessageTime;
}

export function prepareChats(chats: IChat[]) {
  const uniqChats: IEnumerable = {};

  chats.forEach((chat: IChat) => {
    uniqChats[chat.id] = chat;
  });

  return Object.values(uniqChats).sort((chatA, chatB) => compareStrings(chatA.lastMessageTime, chatB.lastMessageTime));
}