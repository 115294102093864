import { useEffect, useState } from 'react';

import { Header } from '../components/Header/Header';
import { SideBar } from '../components/SideBar/SideBar';
import { Content } from '../components/Content';
import { Auth } from '../components/Auth/Auth';

import { useUserStore, IUserStore } from '../store/user';

export function Home() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);
  const [qr, setQr] = useState<string>('');
  const user = useUserStore((state: IUserStore) => state.user);

  useEffect(() => {
    setQr(user.qr || '');
  }, [user]);

  if(!user.id){
    return <Auth />
  }

  return (
    <>
      <Header sideBarActive={sideBarActive} user={user} />
      <SideBar setSideBarActive={setSideBarActive} />
      <Content sideBarActive={sideBarActive} >
        <div className="qr-code">
          <svg width="90" height="90">
             <image xlinkHref={qr} width="90" height="90"/>
          </svg>
        </div>
      </Content>
    </>
  );
}
