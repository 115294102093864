import { useState, useEffect  } from "react";

import { NavItem } from "./NavItem";

import dashBoardIcon from '../../assets/dashboard.png';

import { Home } from "../Icons/Home";
import { Chat } from "../Icons/Chat";
import { Send } from "../Icons/Send";
import { Docs } from "../Icons/Docs";

export function SideBar({ setSideBarActive }: { setSideBarActive:  React.Dispatch<React.SetStateAction<boolean>> }) {
  const [activeClass, setActiveClass] = useState<string>('');
  const items = [
    { 
      id: 0,
      name: 'Главная',
      icon: Home,
      path: '/'
    },
    { 
      id: 1,
      name: 'Чат',
      target: '_blank',
      icon: Chat,
      path: '/chat',
    },
    { 
      id: 2,
      name: 'Рассылки',
      icon: Send,
      path: '/send'
    },
    { 
      id: 3,
      name: 'Документация',
      icon: Docs,
      path: '/documentation'
    },
  ];

  const hoverHandler = (hover: boolean) => {
    setActiveClass(hover ? 'active' : ''); 
    setSideBarActive(hover);
  }

  return (
    <div className={`sidebar ${activeClass}`} onMouseEnter={() => hoverHandler(true)} onMouseLeave={() => hoverHandler(false)}>
      <div className="sidebar__header">
        <img src={dashBoardIcon} alt="dashboard" className="sidebar__header__icon" />
        <p className="sidebar__header__text">Дашборд</p>
      </div>
      <div className="sidebar__inner">
        <div className="sidebar__nav">
          {
            items && items.map(item => {
              return <NavItem path={item.path} name={item.name} target={item.target? item.target : ''}icon={item.icon} key={item.id} />
            })
          }
        </div>
      </div>
    </div>
  );
}
