import create from 'zustand';
import { sendFilterStatuses } from '../helpers/sendFilterStatuses';

import { ISendFilterButton } from '../types/ISendFilterButton';

export interface ISendFilterButtonsStore {
  buttons: ISendFilterButton[]
  setActive: (id: string | number) => void
  inactivateButtons: () => void
}

const initialState= [
  { 
    id: 1, 
    name: 'Все', 
    value: sendFilterStatuses.all, 
    active: true 
  }, 
  { 
    id: 2, 
    name: 'Неактивные', 
    value: sendFilterStatuses.created,
    active: false 
  }, 
  { 
    id: 3, 
    name: 'Активные', 
    value: sendFilterStatuses.work,
    active: false 
  }, 
  { 
    id: 4, 
    name: 'Завершены', 
    value: sendFilterStatuses.complete,
    active: false,
  },
  { 
    id: 5, 
    name: 'Другое', 
    value: sendFilterStatuses.error,
    active: false 
  }
];

export const useSendFilterButtonsStore= create<ISendFilterButtonsStore>()((set: any) => ({
  buttons: initialState,
  setActive: (id: string | number) => set((state: ISendFilterButtonsStore) => { 
    return {
      buttons: state.buttons.map(btn => {
        btn.active = btn.id === id
        return btn;
      })
    }
  }),
  inactivateButtons: () => set((state: ISendFilterButtonsStore) => { 
    return {
      buttons: state.buttons.map(btn => { 
        btn.active = false 
        return btn;
      })
    }
  })
}));
