import React, { useState } from 'react';

export function Delete() {
  const [fillColor, setFillColor] = useState<string>('white');
  const [active, setActive] = useState<boolean>(false);

  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? '#dc3545' : 'white');
    setActive(hover);
  }

  return (
    <div className="send__body__mailing__item__button__icon">
      <div className={`send__body__mailing__item__button__icon__popup delete ${active ? 'active' : ''}`}>
        <div className="send__body__mailing__item__button__icon__popup__text">Удалить рассылку</div> 
        <div className="send__body__mailing__item__button__icon__popup__angle delete"></div> 
      </div>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet" fill={fillColor}
        onMouseEnter={() => hoverHandler(true)} 
        onMouseLeave={() => hoverHandler(false)}
        >
        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        stroke="none" fill={fillColor}>
        <path d="M216 624 c-9 -8 -19 -26 -22 -40 -5 -21 -12 -24 -54 -24 -72 0 -106
        -53 -60 -95 19 -17 20 -31 20 -201 0 -184 6 -226 38 -250 24 -19 340 -19 364
        0 32 24 38 66 38 250 0 170 1 184 20 201 47 42 12 95 -63 95 -39 0 -47 3 -53
        23 -15 49 -31 57 -125 57 -66 0 -91 -4 -103 -16z m193 -33 c6 -10 7 -21 4 -25
        -8 -8 -178 -8 -186 0 -3 4 -2 15 4 25 8 16 22 19 89 19 67 0 81 -3 89 -19z
        m136 -86 c0 -19 -8 -20 -219 -23 -130 -1 -224 2 -229 7 -5 5 -7 17 -4 26 6 13
        34 15 229 13 215 -3 223 -4 223 -23z m-35 -243 c0 -154 -3 -192 -16 -210 -15
        -21 -21 -22 -174 -22 -153 0 -159 1 -174 22 -13 18 -16 56 -16 210 l0 188 190
        0 190 0 0 -188z"/>
        <path d="M220 255 c0 -121 3 -156 13 -152 17 5 17 299 0 305 -10 3 -13 -32
        -13 -153z"/>
        <path d="M305 388 c-11 -45 -1 -281 13 -286 9 -3 12 32 12 152 0 95 -4 156
        -10 156 -5 0 -12 -10 -15 -22z"/>
        <path d="M394 397 c-2 -7 -3 -75 -2 -152 2 -100 6 -140 16 -143 9 -3 12 32 12
        152 0 101 -4 156 -10 156 -6 0 -13 -6 -16 -13z"/>
        </g>
      </svg>
    </div>
  );
}