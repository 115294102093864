import React, { useEffect, useState } from 'react';

export function Start({ started }: { started: boolean }) {
  const [fillColor, setFillColor] = useState<string>('white');
  const [active, setActive] = useState<boolean>(false);
  const [currentColor, setCurrentColor]  = useState<string>('#6610f2');
  const [currentClassName, setCurrentClassName] = useState<string>('start');

  useEffect(() => {
    setCurrentColor(started ? '#53bdeb' : '#6610f2');
    setCurrentClassName(started ? 'stop' : 'start');
  }, [started]);

  const hoverHandler = (hover: boolean) => {
    setFillColor(hover ? currentColor : 'white');
    setActive(hover);
  }

  if (started) {
    return (
      <div className="send__body__mailing__item__button__icon">
        <div className={`send__body__mailing__item__button__icon__popup ${currentClassName} ${active ? 'active' : ''}`}>
          <div className="send__body__mailing__item__button__icon__popup__text">Остановить рассылку (после нажатия, рассылка станет неактивной)</div> 
          <div className={`send__body__mailing__item__button__icon__popup__angle ${currentClassName}`}></div> 
        </div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
          preserveAspectRatio="xMidYMid meet"
          fill={fillColor}
          onMouseEnter={() => hoverHandler(true)} 
          onMouseLeave={() => hoverHandler(false)}
          >

          <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
            stroke="none"
            fill={fillColor}
          >
            <path d="M223 622 c-109 -39 -178 -112 -210 -221 -29 -102 4 -228 82 -306 122
            -121 328 -121 450 0 91 92 118 241 64 356 -69 146 -241 223 -386 171z m215
            -64 c23 -11 44 -23 47 -27 2 -4 -81 -92 -184 -195 -186 -186 -188 -187 -202
            -164 -83 130 -38 311 93 379 82 42 165 44 246 7z m122 -125 c45 -95 22 -223
            -52 -300 -83 -85 -232 -101 -336 -34 -23 14 -22 16 164 202 103 103 191 186
            195 184 4 -3 17 -26 29 -52z"/>
          </g>
        </svg>
      </div>
    );
  } 

    return (
      <div className="send__body__mailing__item__button__icon">
        <div className={`send__body__mailing__item__button__icon__popup start ${active ? 'active' : ''}`}>
          <div className="send__body__mailing__item__button__icon__popup__text">Запустить рассылку (после запуска рассылка станет активной)</div> 
          <div className="send__body__mailing__item__button__icon__popup__angle start"></div> 
        </div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
          preserveAspectRatio="xMidYMid meet"
          fill={fillColor}
          onMouseEnter={() => hoverHandler(true)} 
          onMouseLeave={() => hoverHandler(false)}
        >

        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        stroke="none"
        fill={fillColor}
        >
        <path d="M129 531 l-29 -29 0 -182 0 -182 29 -29 c18 -18 40 -29 57 -29 35 0
        307 153 334 187 25 33 25 73 0 106 -27 34 -299 187 -334 187 -17 0 -39 -11
        -57 -29z m208 -103 c139 -80 158 -95 147 -121 -4 -11 -246 -157 -285 -172 -9
        -3 -24 1 -33 10 -23 24 -24 326 0 349 8 9 18 16 22 16 4 0 71 -37 149 -82z"/>
        </g>
      </svg>
    </div>
  );
}