import React, { useState } from 'react';

import { Header } from '../components/Header/Header';
import { SideBar } from '../components/SideBar/SideBar';
import { Content } from '../components/Content';

import { SettingsBlock } from '../components/Settings/SettingsBlock';

import { useUserStore, IUserStore } from '../store/user';

import { Auth } from '../components/Auth/Auth';

export function Settings() {
  const [sideBarActive, setSideBarActive] = useState<boolean>(false);
  const user = useUserStore((state: IUserStore) => state.user);

  
  if(!user.id){
    return <Auth />
  }
  
  return (
    <>
      <Header sideBarActive={sideBarActive} user={user} />
      <SideBar setSideBarActive={setSideBarActive} />
      <Content sideBarActive={sideBarActive}>
        <SettingsBlock />
      </Content>
    </>
  );
}
