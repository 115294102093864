import React, { useState, useEffect } from 'react';

import { IChat } from "../../types/IChat";
import { getTimeFromTimeStamp } from '../../helpers/getTimeFromTimestamp';

import userIcon from "../../assets/user.png";
import { cursorTo } from 'readline';

export function ChatItem({ chat, setCurrentChat, currentChat, setActiveChats}: { 
  chat: IChat, 
  setCurrentChat: React.Dispatch<React.SetStateAction<IChat | undefined>>,
  currentChat: IChat | undefined,

  setActiveChats: React.Dispatch<React.SetStateAction<boolean>>,
}
) {
  const [isCurrent, setIsCurrent] = useState<boolean>();
  const [hasAvatar, setHasAvatar] = useState<boolean>();

  useEffect(() => {
    setIsCurrent(chat?.id === currentChat?.id);
    setHasAvatar(!!(chat?.image && chat?.image !== 'undefined' && chat?.image !== "0"));
  }, [chat, currentChat]);

  return (
    <div className={`chat__item ${isCurrent ? "current" : ""}`} onClick={() => {
            setCurrentChat(chat)
            setActiveChats(false)}}>
      <div className="chat__item__inner">
        <div className="chat__item__image__block">
          <img src={hasAvatar ? chat.image : userIcon} alt="avatar" className={`chat__item__image ${hasAvatar ? '' : 'invert'}`} />
        </div>

        <div className="chat__item__info">
          <p className="chat__item__name">{ chat.name }</p>
          <div className="chat__item__info__bottom">
            <p className="chat__item__message">{ chat.lastMessageText }</p>
            <p className="chat__item__time">{ getTimeFromTimeStamp(+chat.lastMessageTime * 1000) }</p>
          </div>
        </div>

      </div>
    </div>
  );
}
