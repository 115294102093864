import React, { useState, useEffect } from 'react';

export function Docs({ fillColor }: { fillColor: string }) {
  const [currentFillColor, setCurrentFillColor] = useState<string>('white');

  useEffect(() => {
    setCurrentFillColor(fillColor);
  }, [fillColor]);

  return (
    <svg 
      version="1.0" 
      xmlns="http://www.w3.org/2000/svg"
      width="32.000000pt" height="32.000000pt" 
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
      fill={currentFillColor} 
      className="sidebar__nav__item__icon"
    >

      <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      stroke="none">
      <path d="M44 307 c-2 -7 -3 -78 -2 -157 l3 -145 115 0 115 0 3 119 3 119 -38
      39 c-37 38 -38 38 -115 38 -55 0 -80 -4 -84 -13z m148 -39 c3 -29 7 -33 36
      -36 l32 -3 0 -105 0 -104 -100 0 -100 0 0 140 0 140 64 0 65 0 3 -32z m46 -24
      c-10 -10 -28 6 -28 24 0 15 1 15 17 -1 9 -9 14 -19 11 -23z"/>
      <path d="M100 180 c0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -27 10 -60 10 -33
      0 -60 -4 -60 -10z"/>
      <path d="M100 140 c0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -27 10 -60 10 -33
      0 -60 -4 -60 -10z"/>
      <path d="M100 100 c0 -6 27 -10 60 -10 33 0 60 4 60 10 0 6 -27 10 -60 10 -33
      0 -60 -4 -60 -10z"/>
      <path d="M123 63 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
      </g>
    </svg>
  ); 
}
